import Sidebar from 'components/Sidebar';
import { memo, useEffect, useMemo } from 'react';
import classes from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { IReducer } from 'redux/reducers';
import UserService from 'services/user_service';
import { getCollabUserListRequest, setCollabUserListReducer, setUserReducer } from 'redux/reducers/User/actionTypes';
import { setIsArchivedReducer, setWorkspaceReducer, setProjectReducer, setPipelineReducer } from 'redux/reducers/Workspace/actionTypes';
import {
  getArchivedProjectListRequest,
  getWorkspaceListRequest,
  getProjectListRequest,
  getPipelineListRequest,
  setPipelineListReducer,
} from 'redux/reducers/Information/actionTypes';
import { routes } from 'routers/routes';
import useRouteMatch from 'hooks/useRouteMatch';
import QueryString from 'qs';
import { IWorkspace } from 'interfaces/workspace';
import { NULL_VALUE } from 'configs/constant';

interface IQueryString {
  workspaceId?: string;
}

interface AuthorizedLayoutProps {
  children: React.ReactNode;
}

const AuthorizedLayout: React.FC<AuthorizedLayoutProps> = memo((props: AuthorizedLayoutProps) => {
  const { children } = props;

  const user = useMemo(() => UserService.getUser(), []);

  const dispatch = useDispatch();
  const { isExact: isProjectPage } = useRouteMatch(routes.private.project.default);
  const { isExact: isProjectTaskViewPage } = useRouteMatch(routes.private.project.taskView);
  const { isExact: isEditProjectPage } = useRouteMatch(routes.private.project.edit);

  const { workspaceId }: IQueryString = QueryString.parse(window?.location?.search);

  const { user: userRedux, collabUserList, sortUserTable } = useSelector((state: IReducer) => state?.user);
  const { workspace, project, pipeline } = useSelector((state: IReducer) => state?.workspace);
  const { workspaceList, projectList, archivedProjectList, pipelineList } = useSelector((state: IReducer) => state?.information);

  useEffect(() => {
    if (!workspaceList?.length) {
      dispatch(getWorkspaceListRequest());
    }
  }, []);

  useEffect(() => {
    if (archivedProjectList && !archivedProjectList?.length) {
      dispatch(setIsArchivedReducer(false));
    }
  }, [archivedProjectList]);

  useEffect(() => {
    if (user && !userRedux) {
      dispatch(setUserReducer(user));
    }
  }, [user, userRedux]);

  useEffect(() => {
    if (workspaceList?.length) {
      if (workspaceId && workspaceId !== NULL_VALUE) {
        dispatch(
          setWorkspaceReducer(workspaceList?.find((workspace: IWorkspace) => workspace?._id === workspaceId) ?? workspaceList?.[0])
        );
      } else {
        if (!workspace) {
          dispatch(setWorkspaceReducer(workspaceList?.[0]));
        }
      }
    }
  }, [workspaceId, workspaceList, workspace]);

  useEffect(() => {
    if (project && project?._id !== pipelineList?.projectId && !pipelineList?.isLoading) {
      if (project?.pipeline_ids?.length) {
        dispatch(getPipelineListRequest(project?._id));
      } else {
        dispatch(setPipelineListReducer({ isLoading: false, projectId: project?._id, data: [] }));
      }
    }
  }, [project, pipelineList]);

  useEffect(() => {
    if (user && !collabUserList && sortUserTable) {
      if (user?.project_ids?.length) {
        dispatch(getCollabUserListRequest(user?.project_ids?.join(','), sortUserTable?.column, sortUserTable?.direction));
      } else {
        dispatch(setCollabUserListReducer([]));
      }
    }
  }, [user, collabUserList, sortUserTable]);

  useEffect(() => {
    if (!isEditProjectPage) {
      if (projectList?.length) {
        if (!project) {
          dispatch(setProjectReducer(projectList?.[0]));
        }
      } else {
        dispatch(setProjectReducer(null));
      }
    }
  }, [isEditProjectPage, projectList, project]);

  useEffect(() => {
    if (workspace) {
      if (!projectList && !archivedProjectList) {
        dispatch(getProjectListRequest(workspace?._id));
      }
      if (!archivedProjectList && !projectList) {
        dispatch(getArchivedProjectListRequest(workspace?._id));
      }
    }
  }, [workspace, projectList, archivedProjectList]);

  useEffect(() => {
    if (!isProjectPage && !isProjectTaskViewPage) {
      if (pipelineList?.data?.length) {
        if (!pipeline) {
          dispatch(setPipelineReducer(pipelineList?.data?.[0]));
        }
      } else {
        dispatch(setPipelineReducer(null));
      }
    }
  }, [isProjectPage, isProjectTaskViewPage, pipelineList, pipeline]);

  return (
    <div className={classes.container}>
      <Sidebar />
      <div className={classes.main}>{children}</div>
    </div>
  );
});

export default AuthorizedLayout;
