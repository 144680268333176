import produce from 'immer';
import { SET_IS_LOADING_REDUCER } from './actionTypes';

export interface IStatusState {
  isLoading: boolean;
}

const initialState: IStatusState = {
  isLoading: false,
};

export const statusReducer = (state: IStatusState = initialState, action: { type: string; data: any }) =>
  produce(state, (draft) => {
    switch (action?.type) {
      case SET_IS_LOADING_REDUCER:
        draft.isLoading = action?.data;
        break;
      default:
        return state;
    }
  });
