import { Fragment, memo, useEffect, useMemo, useState } from 'react';
import classes from './styles.module.scss';
import Input from 'components/Input';
import Select from 'components/Select';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Dropzone from 'components/Dropzone';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { useDispatch, useSelector } from 'react-redux';
import { IReducer } from 'redux/reducers';
import { ISelectOption } from 'interfaces/common';
import { IEditProjectUrlParams, IGeneralSettingsFormData, IProject, ITaskConfigurationStatus } from 'interfaces/project';
import { useParams } from 'react-router';
import ApiService from 'services/api_service';
import ToastService from 'services/toast_service';
import Messages from 'configs/messages';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import QueryString from 'qs';
import DangerButton from 'components/Buttons/DangerButton';
import useRouteMatch from 'hooks/useRouteMatch';
import { routes } from 'routers/routes';
import { setArchivedProjectListReducer, setProjectListReducer } from 'redux/reducers/Information/actionTypes';
import { setAllProjectListReducer, setProjectReducer } from 'redux/reducers/Workspace/actionTypes';
import ApiRoutes from 'configs/apiRoutes';
import ModalDeleteProject from './components/ModalDeleteProject';
import { push } from 'connected-react-router';
import { EProjectSettings, ESelectTheme, EStatusCode } from 'configs/enums';
import { NULL_OPTION, NULL_VALUE } from 'configs/constant';
import { Skeleton } from '@mui/material';
import ProjectService from 'services/project_service';
import { getCollabUserListRequest, setUserReducer } from 'redux/reducers/User/actionTypes';
import { IUser } from 'interfaces/user';
import UserService from 'services/user_service';
import clsx from 'clsx';

interface GeneralSettingsProps {}

const GeneralSettings = memo((props: GeneralSettingsProps) => {
  const dispatch = useDispatch();
  const { projectId } = useParams<IEditProjectUrlParams>();
  const { name } = QueryString.parse(window?.location?.search);
  const { isExact: isCreate } = useRouteMatch(routes.private.project.create);
  const { isExact: isEdit } = useRouteMatch(routes.private.project.edit);

  const { user, sortUserTable } = useSelector((state: IReducer) => state?.user);
  const { workspace, project, allProjectList } = useSelector((state: IReducer) => state?.workspace);
  const { projectTemplates, projectList, archivedProjectList } = useSelector((state: IReducer) => state?.information);

  const [projectTemplateOptions, setProjectTemplateOptions] = useState<ISelectOption[]>([]);
  const [isOpenModalDeleteProject, setIsOpenModalDeleteProject] = useState<boolean>(false);
  const [projectTemplateDescription, setProjectTemplateDescription] = useState<IProject>(null);

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      text: Yup.string().required('This field is required.'),
      selectedProjectTemplate: Yup.object(),
      image: Yup.mixed(),
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm<IGeneralSettingsFormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const selectedProjectTemplate = watch('selectedProjectTemplate');

  useEffect(() => {
    if (projectTemplates) {
      setProjectTemplateOptions([
        NULL_OPTION,
        ...projectTemplates.map((item: IProject) => {
          return {
            value: item?._id,
            label: item?.name,
          };
        }),
      ]);
    }
  }, [projectTemplates]);

  useEffect(() => {
    if (isEdit && project) {
      setValue('text', project?.name);
    }
  }, [isEdit, project]);

  useEffect(() => {
    if (selectedProjectTemplate && projectTemplates?.length) {
      setProjectTemplateDescription(projectTemplates?.find((template: IProject) => template?._id === selectedProjectTemplate?.value) ?? null);
    }
  }, [selectedProjectTemplate, projectTemplates]);

  const onUploadImage = (file: File[]) => {
    setValue('image', file, { shouldValidate: true });
  };

  const onSubmit = (data: IGeneralSettingsFormData) => {
    dispatch(setIsLoadingReducer(true));
    const image = data?.image?.[0];
    if (image) {
      const reader = new FileReader();
      reader.onload = async (event: ProgressEvent<FileReader>) => {
        if (isEdit) {
          await ApiService.PATCH(ApiRoutes.project.info.replace(':projectId', projectId), {
            name: data?.text,
          })
            .then(async (projectResponse) => {
              await ApiService.POST(ApiRoutes.project.titleImages?.replace(':projectId', projectResponse?.entities?.[0]?._id), [
                {
                  content: event?.target?.result,
                },
              ])
                .then((imageResponse) => {
                  const updatedProject: IProject = imageResponse?.entities?.[0];
                  dispatch(
                    setProjectListReducer(
                      projectList?.map((item: IProject) => {
                        if (item?._id === updatedProject?._id) {
                          return updatedProject;
                        }
                        return item;
                      })
                    )
                  );
                  dispatch(setProjectReducer(updatedProject));
                  ToastService.success(Messages.success.default);
                })
                .catch((error) => {
                  if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
                    ToastService.error(Messages.error.accessDenied);
                    return;
                  }
                  console.log(error);
                  ToastService.error(Messages.error.default);
                });
            })
            .catch((error) => {
              console.log(error);
              ToastService.error(Messages.error.default);
            })
            .finally(() => dispatch(setIsLoadingReducer(false)));
        } else {
          await ApiService.POST(ApiRoutes.project.default, {
            name: data?.text,
            workspace_id: workspace?._id,
            template_project_id: data?.selectedProjectTemplate?.value === NULL_OPTION?.value ? undefined : data?.selectedProjectTemplate?.value,
          })
            .then(async (projectResponse) => {
              await ApiService.POST(ApiRoutes.project.titleImages?.replace(':projectId', projectResponse?.entities?.[0]?._id), [
                {
                  content: event?.target?.result,
                },
              ])
                .then((imageResponse) => {
                  const newProject: IProject = imageResponse?.entities?.[0];

                  UserService.setUser({ ...user, project_ids: [...(user?.project_ids ?? []), newProject?._id] });
                  ProjectService.setRecentProjectId(newProject?._id);

                  dispatch(setUserReducer({ ...user, project_ids: [...(user?.project_ids ?? []), newProject?._id] }));
                  dispatch(setProjectReducer(newProject));
                  dispatch(setProjectListReducer([newProject, ...projectList]));
                  dispatch(setAllProjectListReducer([newProject, ...allProjectList]));
                  dispatch(
                    push({
                      pathname: routes.private.project.edit.replace(':workspaceId', workspace?._id).replace(':projectId', newProject?._id),
                      search: `&name=${encodeURIComponent(newProject?.name)}&type=${EProjectSettings.Pipeline}&workspaceId=${
                        workspace?._id ?? NULL_VALUE
                      }`,
                    })
                  );
                  ToastService.success(Messages.success.created);
                })
                .catch((error) => {
                  if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
                    ToastService.error(Messages.error.accessDenied);
                    return;
                  }
                  console.log(error);
                  ToastService.error(Messages.error.default);
                });
            })
            .catch((error) => {
              console.log(error);
              ToastService.error(Messages.error.default);
            })
            .finally(() => dispatch(setIsLoadingReducer(false)));
        }
      };
      reader.readAsDataURL(image);
    } else {
      if (isEdit) {
        ApiService.PATCH(ApiRoutes.project.info.replace(':projectId', projectId), {
          name: data?.text,
        })
          .then((response) => {
            const updatedProject = response?.entities?.[0];
            dispatch(
              setProjectListReducer(
                projectList?.map((project: IProject) => {
                  if (project?._id === updatedProject?._id) {
                    return updatedProject;
                  }
                  return project;
                })
              )
            );
            dispatch(
              setAllProjectListReducer(
                allProjectList?.map((project: IProject) => {
                  if (project?._id === updatedProject?._id) {
                    return updatedProject;
                  }
                  return project;
                })
              )
            );
            dispatch(setProjectReducer(updatedProject));
            ToastService.success(Messages.success.default);
          })
          .catch((error) => {
            if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
              ToastService.error(Messages.error.accessDenied);
              return;
            }
            console.log(error);
            ToastService.error(Messages.error.default);
          })
          .finally(() => dispatch(setIsLoadingReducer(false)));
      } else {
        ApiService.POST(ApiRoutes.project.default, {
          name: data?.text,
          workspace_id: workspace?._id,
          template_project_id: data?.selectedProjectTemplate?.value === NULL_OPTION?.value ? undefined : data?.selectedProjectTemplate?.value,
        })
          .then((response) => {
            const newProject: IProject = response?.entities?.[0];

            UserService.setUser({ ...user, project_ids: [...(user?.project_ids ?? []), newProject?._id] });
            ProjectService.setRecentProjectId(newProject?._id);

            dispatch(setUserReducer({ ...user, project_ids: [...(user?.project_ids ?? []), newProject?._id] }));
            dispatch(setProjectReducer(newProject));
            dispatch(setProjectListReducer([newProject, ...projectList]));
            dispatch(setAllProjectListReducer([newProject, ...allProjectList]));
            dispatch(
              push({
                pathname: routes.private.project.edit.replace(':workspaceId', workspace?._id).replace(':projectId', newProject?._id),
                search: `&name=${encodeURIComponent(newProject?.name)}&type=${EProjectSettings.Pipeline}&workspaceId=${workspace?._id ?? NULL_VALUE}`,
              })
            );
            ToastService.success(Messages.success.created);
          })
          .catch((error) => {
            if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
              ToastService.error(Messages.error.accessDenied);
              return;
            }
            console.log(error);
            ToastService.error(Messages.error.default);
          })
          .finally(() => dispatch(setIsLoadingReducer(false)));
      }
    }
  };

  const handleArchiveProject = () => {
    dispatch(setIsLoadingReducer(true));
    ApiService.DELETE(ApiRoutes.project.archive.replace(':projectId', projectId))
      .then(() => {
        const updatedProjectList = projectList?.filter((item: IProject) => item?._id !== project?._id);

        dispatch(setProjectReducer(updatedProjectList?.[0]));
        dispatch(setProjectListReducer(updatedProjectList));
        dispatch(setAllProjectListReducer(allProjectList?.filter((item: IProject) => item?._id !== project?._id)));
        dispatch(setArchivedProjectListReducer([project, ...archivedProjectList]));
        ProjectService.setRecentProjectId(updatedProjectList?.[0]?._id);
        ToastService.success(Messages.success.archive);

        dispatch(push({ pathname: routes.private.home, search: `&workspaceId=${workspace?._id ?? NULL_VALUE}` }));
      })
      .catch((error) => {
        if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
          ToastService.error(Messages.error.accessDenied);
          return;
        }
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const handleDeleteProject = () => {
    dispatch(setIsLoadingReducer(true));
    ApiService.DELETE(ApiRoutes.project.delete.replace(':projectId', projectId).replace(':projectName', name.toString()))
      .then(() => {
        const updatedProjectList: IProject[] = projectList?.filter((project: IProject) => project?._id !== projectId);
        const updatedUser: IUser = { ...user, project_ids: user?.project_ids?.filter((item: string) => item !== projectId) };

        dispatch(setProjectReducer(updatedProjectList?.[0] ?? null));
        dispatch(setProjectListReducer(updatedProjectList));
        dispatch(setAllProjectListReducer(allProjectList?.filter((project: IProject) => project?._id !== projectId)));

        dispatch(getCollabUserListRequest(updatedUser?.project_ids?.join(','), sortUserTable?.column, sortUserTable?.direction));
        dispatch(setUserReducer(updatedUser));
        UserService.setUser(updatedUser);

        onCloseModals();
        ToastService.success(Messages.success.deleted);

        dispatch(push({ pathname: routes.private.home, search: `&workspaceId=${workspace?._id ?? NULL_VALUE}` }));
      })
      .catch((error) => {
        if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
          ToastService.error(Messages.error.accessDenied);
          return;
        }
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const onCloseModals = () => {
    setIsOpenModalDeleteProject(false);
  };

  return (
    <div className={classes.container}>
      <div>
        <p className={classes.description}>Configure your project settings.</p>

        <form id="general-setting" onSubmit={handleSubmit(onSubmit)}>
          {isCreate || project ? (
            <Input label="Project name" placeholder="Project name" inputRef={register('text')} errorMessage={errors?.text?.message} />
          ) : (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={60}
              sx={{ marginBottom: '24px', borderRadius: '8px', background: 'var(--backgroundLight)' }}
            />
          )}

          {isEdit ? null : (
            <Select
              customTheme={ESelectTheme.Form}
              name="selectedProjectTemplate"
              control={control}
              errorMessage={errors?.selectedProjectTemplate?.message as string}
              label="Project template"
              options={projectTemplateOptions}
              defaultValue={NULL_OPTION}
            />
          )}

          {isCreate && projectTemplateDescription ? (
            <div className={classes.projectTemplateDescriptionContainer}>
              <label>Template description</label>
              <div className={classes.projectTemplateDescription}>
                {projectTemplateDescription?.description ? (
                  <p className={clsx(classes.description, 'mb-3')}>{projectTemplateDescription?.description}</p>
                ) : null}

                {projectTemplateDescription?.task_configuration?.statuses?.length ? (
                  <div className={classes.statuses}>
                    <label>Statuses</label>
                    <div>
                      {projectTemplateDescription?.task_configuration?.statuses?.map((status: ITaskConfigurationStatus, statusIndex: number) => {
                        return status?.name ? (
                          <div key={`status-${statusIndex}`} style={{ borderColor: `#${status?.color}` }}>
                            {status?.name}
                          </div>
                        ) : null;
                      })}
                    </div>
                  </div>
                ) : null}

                {Object.keys(projectTemplateDescription?.app_configuration)?.length ? (
                  <div className={classes.chip}>
                    <label>Apps</label>
                    <div>
                      {Object.keys(projectTemplateDescription?.app_configuration)?.map((appName: string) => {
                        return <div key={appName}>{appName}</div>;
                      })}
                    </div>
                  </div>
                ) : null}

                {projectTemplateDescription?.tags?.length ? (
                  <div className={classes.chip}>
                    <label>Tags</label>
                    <div>
                      {projectTemplateDescription?.tags?.map((tag: string) => {
                        return <div key={tag}>{tag}</div>;
                      })}
                    </div>
                  </div>
                ) : null}

                <p className={clsx(classes.description, 'mb-0')}>You are able to completely customize any aspect of your project configuration.</p>
              </div>
            </div>
          ) : null}

          {isCreate || project ? (
            <Dropzone defaultImage={isCreate ? null : project?.title_image_urls?.[0]} dropzoneClassName="w-100" label="Image" onSubmit={onUploadImage} />
          ) : (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={310.21}
              sx={{ marginBottom: '24px', borderRadius: '8px', background: 'var(--backgroundLight)' }}
            />
          )}
        </form>
      </div>

      <div className={classes.footerContainer}>
        <PrimaryButton type="submit" form="general-setting" fullWidth>
          {isEdit ? 'Save' : 'Create project'}
        </PrimaryButton>
        {isEdit ? (
          <Fragment>
            <hr />
            <DangerButton onClick={handleArchiveProject} fullWidth>
              Archive project
            </DangerButton>
            <DangerButton onClick={() => setIsOpenModalDeleteProject(true)} fullWidth>
              Delete project
            </DangerButton>
          </Fragment>
        ) : null}
      </div>

      <ModalDeleteProject isOpen={isOpenModalDeleteProject} onClose={onCloseModals} onSubmit={handleDeleteProject} />
    </div>
  );
});

export default GeneralSettings;
