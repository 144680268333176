import ApiRoutes from 'configs/apiRoutes';
import { EStatusCode } from 'configs/enums';
import Messages from 'configs/messages';
import { IResource } from 'interfaces/pipeline';
import { put, takeLatest, call } from 'redux-saga/effects';
import { GET_RESOURCE_SUMMARY_REQUEST, setResourceSummaryReducer } from 'redux/reducers/Information/actionTypes';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import {} from 'redux/reducers/Workspace/actionTypes';
import ApiService from 'services/api_service';
import ToastService from 'services/toast_service';

function* requestGetResourceSummary(data: { type: string; resource: IResource; options?: { noSkeleton?: boolean; loading?: boolean } }) {
  try {
    if (!data?.options?.noSkeleton) {
      yield put(
        setResourceSummaryReducer({
          isLoading: true,
          pipelineId: data?.resource?.pipeline_id,
          data: null,
        })
      );
    }
    if (data?.options?.loading) {
      yield put(setIsLoadingReducer(true));
    }

    const response = yield call(ApiService.GET, ApiRoutes.resource.summary.replace(':resourceId', data?.resource?._id));

    yield put(
      setResourceSummaryReducer({
        isLoading: false,
        pipelineId: data?.resource?.pipeline_id,
        data: response?.entities?.[0],
      })
    );
  } catch (error: any) {
    yield put(
      setResourceSummaryReducer({
        isLoading: false,
        pipelineId: null,
        data: null,
      })
    );
    if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
      yield ToastService.error(Messages.error.accessDenied);
    } else {
      yield console.log(error);
      yield ToastService.error(Messages.error.default);
    }
  } finally {
    if (data?.options?.loading) {
      yield put(setIsLoadingReducer(false));
    }
  }
}

function* getResourceSummary() {
  yield takeLatest(GET_RESOURCE_SUMMARY_REQUEST, requestGetResourceSummary);
}

export default getResourceSummary;
