import { memo } from 'react';
import classes from './styles.module.scss';

interface LoadingOverlayProps {}

const LoadingOverlay: React.FC<LoadingOverlayProps> = memo((props: LoadingOverlayProps) => {
  return (
    <div className={classes.root}>
      <div className={classes.loading}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
});

export default LoadingOverlay;
