import produce from 'immer';
import {
  SET_ARCHIVED_PROJECT_LIST_REDUCER,
  SET_LOGOUT_INFORMATION_REDUCER,
  SET_WORKSPACE_LIST_REDUCER,
  SET_PROJECT_TEMPLATES_REDUCER,
  SET_PROJECT_LIST_REDUCER,
  SET_PUBLISHED_PIPELINE_LIST_REDUCER,
  SET_PIPELINE_LIST_REDUCER,
  SET_PIPELINE_TEMPLATES_REDUCER,
  SET_RESOURCE_LIST_REDUCER,
  SET_RESOURCE_SUMMARY_REDUCER,
  SET_GLOBAL_APP_CONFIGURATIONS_REDUCER,
} from './actionTypes';
import { IProject } from 'interfaces/project';
import { IWorkspace } from 'interfaces/workspace';
import {
  IPipeline,
  IPublishedPipelineListState,
  IPipelineListState,
  IResourceListState,
  IResourceSummaryState,
  IGlobalAppConfiguration,
} from 'interfaces/pipeline';

export interface IInformationState {
  workspaceList: IWorkspace[];
  projectList: IProject[];
  archivedProjectList: IProject[];
  projectTemplates: IProject[];
  pipelineTemplates: IPipeline[];
  publishedPipelineList: IPublishedPipelineListState;
  pipelineList: IPipelineListState;
  resourceList: IResourceListState;
  resourceSummary: IResourceSummaryState;
  globalAppConfigurations: IGlobalAppConfiguration[];
}

const initialState: IInformationState = {
  // null for handling skeletons
  workspaceList: null,
  projectList: null,
  archivedProjectList: null,
  projectTemplates: null,
  pipelineTemplates: null,
  publishedPipelineList: { isLoading: false, pipelineId: null, data: null },
  pipelineList: { isLoading: false, projectId: null, data: null },
  resourceList: { isLoading: false, projectId: null, pipelineId: null, data: null, resourceConfigurations: null, isPublished: true },
  resourceSummary: { isLoading: false, pipelineId: null, data: null },
  globalAppConfigurations: null,
};

export const informationReducer = (state: IInformationState = initialState, action: { type: string; data: any }) =>
  produce(state, (draft) => {
    switch (action?.type) {
      case SET_WORKSPACE_LIST_REDUCER:
        draft.workspaceList = action?.data;
        break;
      case SET_PROJECT_LIST_REDUCER:
        draft.projectList = action?.data;
        break;
      case SET_ARCHIVED_PROJECT_LIST_REDUCER:
        draft.archivedProjectList = action?.data;
        break;
      case SET_PROJECT_TEMPLATES_REDUCER:
        draft.projectTemplates = action?.data;
        break;
      case SET_PIPELINE_TEMPLATES_REDUCER:
        draft.pipelineTemplates = action?.data;
        break;
      case SET_PUBLISHED_PIPELINE_LIST_REDUCER:
        draft.publishedPipelineList = action?.data;
        break;
      case SET_PIPELINE_LIST_REDUCER:
        draft.pipelineList = action?.data;
        break;
      case SET_RESOURCE_LIST_REDUCER:
        draft.resourceList = action?.data;
        break;
      case SET_RESOURCE_SUMMARY_REDUCER:
        draft.resourceSummary = action?.data;
        break;
      case SET_GLOBAL_APP_CONFIGURATIONS_REDUCER:
        draft.globalAppConfigurations = action?.data;
        break;
      case SET_LOGOUT_INFORMATION_REDUCER:
        draft.workspaceList = null;
        draft.projectList = null;
        draft.archivedProjectList = null;
        draft.projectTemplates = null;
        draft.pipelineTemplates = null;
        draft.publishedPipelineList = { isLoading: false, pipelineId: null, data: null };
        draft.pipelineList = { isLoading: false, projectId: null, data: null };
        draft.resourceList = { isLoading: false, projectId: null, pipelineId: null, data: null, resourceConfigurations: null, isPublished: true };
        draft.resourceSummary = null;
        draft.globalAppConfigurations = null;
        break;
      default:
        return state;
    }
  });
