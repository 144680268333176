const Messages = {
  success: {
    default: 'Updated successfully.',
    created: 'Created successfully.',
    deleted: 'Deleted successfully.',
    removed: 'Removed successfully.',
    invited: 'Invited successfully.',
    copied: 'Copied successfully.',
    archive: 'Archived successfully.',
    unarchive: 'Unarchived successfully.',
    resourceAdded: ':resource added to :pipelineName.',
  },
  error: {
    default: 'Sorry, something went wrong. Please contact our support team.',
    deleteWorkspace: 'You can not delete the last workspace you have.',
    recaptchaNotReady: 'reCAPTCHA is not available.',
    oneFileOnly: 'Please upload one file only.',
    imageSize: 'Image size exceeds 5MB maximum limit.',
    imageType: 'Only images in JPEG, JPG, or PNG formats are accepted.',
    wrongAccount: 'Incorrect username or password.',
    recaptchaFailed: 'Failed reCAPTCHA verification, please try again.',
    existedEmail: 'This email address is already existed.',
    loginByEmail: 'Account already exists, please login using your email.',
    duplicatedStageName: 'Stage name already exists.',
    emptyPipeline: 'Can not publish empty pipeline.',
    unarchiveProject: 'Please unarchive this project to use it.',
    noPublishedPipeline: 'Can not create asset for non-published pipeline.',
    invitationExpired: 'Your invitation has expired.',
    invitationAccepted: 'You have accepted this invitation.',
    accessDenied: 'You do not have sufficient permissions',
  },
  info: {
    fullAppSettings: 'All the app settings have been applied.',
    projectNotFound: 'Project not found. Please select or create one!',
    savingPipeline: 'Saving pipeline, please try again.',
  },
};

export default Messages;
