import ApiRoutes from 'configs/apiRoutes';
import { ALL_OPTION, NULL_VALUE } from 'configs/constant';
import { EResourceColumns, ESortDirection, EStatusCode } from 'configs/enums';
import Messages from 'configs/messages';
import Regexes from 'configs/regexes';
import { ISelectOption } from 'interfaces/common';
import { IResourceConfiguration } from 'interfaces/pipeline';
import { put, takeLatest, call } from 'redux-saga/effects';
import { GET_RESOURCE_LIST_REQUEST, setResourceListReducer } from 'redux/reducers/Information/actionTypes';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import { setResourceTableReducer } from 'redux/reducers/Workspace/actionTypes';
import ApiService from 'services/api_service';
import ToastService from 'services/toast_service';

function* requestGetResourceList(data: {
  type: string;
  projectId: string;
  pipelineId: string;
  searchKey: string;
  priority: ISelectOption;
  users: ISelectOption[];
  status: ISelectOption;
  page: number;
  limit: number;
  totalPages: number;
  sortColumn: EResourceColumns;
  sortDirection: ESortDirection;
  noSkeleton: boolean;
  resourceConfigurations: IResourceConfiguration[];
}) {
  try {
    if (data?.pipelineId !== NULL_VALUE) {
      if (!data?.noSkeleton) {
        yield put(
          setResourceListReducer({
            isLoading: true,
            projectId: data?.projectId,
            pipelineId: data?.pipelineId,
            data: null,
            resourceConfigurations: data?.resourceConfigurations ?? null,
            isPublished: true,
          })
        );
      } else {
        yield put(setIsLoadingReducer(true));
      }

      const resourceList = yield call(
        ApiService.GET,
        ApiRoutes.resource.list.replace(':projectId', data?.projectId).replace(':searchKey', encodeURIComponent(data?.searchKey)),
        {
          filter_pipeline_id: data?.pipelineId,
          filter_priority: data?.priority?.value === ALL_OPTION?.value ? undefined : data?.priority?.value,
          filter_assigned_user_id:
            data?.users?.length === 1 && data?.users?.[0]?.value === ALL_OPTION?.value
              ? undefined
              : data?.users?.map((item: ISelectOption) => item?.value)?.join(','),
          filter_status: data?.status?.value === ALL_OPTION?.value ? undefined : data?.status?.value,
          offset: data?.page - 1,
          limit: data?.limit,
          sortBy: data?.sortColumn,
          sortDirection: data?.sortDirection,
        }
      );

      const resourceConfigurations = yield call(ApiService.GET, ApiRoutes.publishedPipeline.latest.replace(':pipelineId', data?.pipelineId));

      yield put(
        setResourceListReducer({
          isLoading: false,
          projectId: data?.projectId,
          pipelineId: data?.pipelineId,
          data: resourceList?.entities ?? [],
          resourceConfigurations: resourceConfigurations?.entities?.[0]?.resource_configurations,
          isPublished: true,
        })
      );
      yield put(
        setResourceTableReducer({
          searchKey: data?.searchKey,
          priority: data?.priority,
          users: data?.users,
          status: data?.status,
          page: data?.page,
          limit: data?.limit,
          totalPages: resourceList?.total_pages ?? data?.totalPages,
          column: data?.sortColumn,
          direction: data?.sortDirection,
        })
      );

      if (data?.noSkeleton) {
        yield put(setIsLoadingReducer(false));
      }
    } else {
      yield put(
        setResourceListReducer({
          isLoading: false,
          projectId: data?.projectId,
          pipelineId: null,
          data: [],
          resourceConfigurations: [],
          isPublished: true,
        })
      );
    }
  } catch (error: any) {
    if (data?.noSkeleton) {
      yield put(setIsLoadingReducer(false));
    }
    if (Regexes.api.latestPublishedPipeline.test(error?.response?.config?.url) && error?.response?.status === EStatusCode.NotFound) {
      yield put(
        setResourceListReducer({
          isLoading: false,
          projectId: data?.projectId,
          pipelineId: data?.pipelineId !== NULL_VALUE ? data?.pipelineId : null,
          data: [],
          resourceConfigurations: [],
          isPublished: false, // hide resource table
        })
      );
    } else {
      if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
        yield ToastService.error(Messages.error.accessDenied);
      } else {
        yield console.log(error);
        yield ToastService.error(Messages.error.default);
      }
    }
  }
}

function* getResourceList() {
  yield takeLatest(GET_RESOURCE_LIST_REQUEST, requestGetResourceList);
}

export default getResourceList;
