import { IComplexityOption, ISelectOption } from 'interfaces/common';
import { EPriority } from './enums';

export const SEARCH_DELAY = 500; // 0.5 second

export const NULL_VALUE = 'null';

export const NULL_LABEL = 'None';

export const NULL_COLOR = '94a3b8';

export const NULL_OPTION = { value: 'null', label: 'None' };

export const ALL_OPTION = { value: 'ALL_OPTION_VALUE', label: 'All' };

export const DATE_FORMAT = 'DD MMM, YYYY';

export const MAX_IMAGE_SIZE = 5242880; // 5 MB

export const PRIORITY_OPTIONS: ISelectOption[] = [
  ALL_OPTION,
  {
    value: EPriority.Highest,
    label: 'Highest',
  },
  {
    value: EPriority.High,
    label: 'High',
  },
  {
    value: EPriority.Medium,
    label: 'Medium',
  },
  {
    value: EPriority.Low,
    label: 'Low',
  },
];

export const COMPLEXITY_OPTIONS: IComplexityOption[] = [
  {
    value: 1,
    label: 'S',
    color: 'var(--green)',
  },
  {
    value: 2,
    label: 'M',
    color: 'var(--yellow)',
  },
  {
    value: 3,
    label: 'L',
    color: 'var(--warning)',
  },
  {
    value: 4,
    label: 'XL',
    color: 'var(--red)',
  },
];

export const DEFAULT_ROLE: ISelectOption = {
  value: 'CONTRIBUTOR',
  label: 'Contributor',
};

export const ROLE_OPTIONS: ISelectOption[] = [
  {
    value: 'ADMIN',
    label: 'Admin',
  },
  {
    value: 'MANAGER',
    label: 'Manager',
  },
  {
    value: 'CONTRIBUTOR',
    label: 'Contributor',
  },
  // {
  //   value: "client",
  //   label: 'Client',
  // },
];
