import { memo } from 'react';
import classes from './styles.module.scss';
import { Sidebar as ReactProSidebar } from 'react-pro-sidebar';
import { ActiveBarIcon, HomeIcon, ProjectIcon, UserIcon } from 'assets';
import { ESidebarMenu } from 'configs/enums';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { IReducer } from 'redux/reducers';
import { routes } from 'routers/routes';
import useRouteMatch from 'hooks/useRouteMatch';
import ToastService from 'services/toast_service';
import Messages from 'configs/messages';
import { NULL_VALUE } from 'configs/constant';
import { setArchivedProjectListReducer, setProjectListReducer } from 'redux/reducers/Information/actionTypes';

interface SidebarProps {}

const Sidebar: React.FC<SidebarProps> = memo((props: SidebarProps) => {
  const dispatch = useDispatch();
  const { isExact: isHomePage } = useRouteMatch(routes.private.home);
  const { isExact: isProjectPage } = useRouteMatch(routes.private.project.default);
  const { isExact: isProjectTaskViewPage } = useRouteMatch(routes.private.project.taskView);
  const { isExact: isUsersPage } = useRouteMatch(routes.private.users);

  const { workspace, project, pipeline } = useSelector((state: IReducer) => state?.workspace);

  const onSelectItem = (type: ESidebarMenu) => {
    switch (type) {
      case ESidebarMenu.Home:
        if (!workspace?._id) {
          dispatch(setProjectListReducer(null));
          dispatch(setArchivedProjectListReducer(null));
        }
        dispatch(
          push({
            pathname: routes.private.home,
            search: `&workspaceId=${workspace?._id ?? NULL_VALUE}`,
          })
        );
        break;
      case ESidebarMenu.Project:
        if (!project) {
          ToastService.info(Messages.info.projectNotFound);
        } else {
          dispatch(
            push({
              pathname: routes.private.project.default
                .replace(':projectId', project?._id)
                .replace(
                  ':pipelineId',
                  project?.pipeline_ids?.includes(pipeline?._id) ? pipeline?._id : project?.pipeline_ids?.length ? project?.pipeline_ids?.[0] : NULL_VALUE
                ),
              search: `&workspaceId=${workspace?._id ?? NULL_VALUE}`,
            })
          );
        }
        break;
      case ESidebarMenu.Users:
        dispatch(push({ pathname: routes.private.users, search: `&workspaceId=${workspace?._id ?? NULL_VALUE}` }));
        break;
      default:
        break;
    }
  };

  return (
    <ReactProSidebar
      width="100px"
      rootStyles={{
        position: 'fixed',
        top: 0,
        left: 0,
        border: 'none',
        boxShadow: 'var(--rightBoxShadow)',
        zIndex: 9999,
      }}
    >
      <div className={classes.container}>
        <div className={classes.menu}>
          <div className={classes.item} onClick={() => onSelectItem(ESidebarMenu.Home)}>
            <HomeIcon className={isHomePage ? classes.activeFill : null} />
            {isHomePage ? <ActiveBarIcon className={classes.activeBar} /> : null}
          </div>

          <div className={classes.item} onClick={() => onSelectItem(ESidebarMenu.Project)}>
            <ProjectIcon className={isProjectPage || isProjectTaskViewPage ? classes.activeFill : null} />
            {isProjectPage || isProjectTaskViewPage ? <ActiveBarIcon className={classes.activeBar} /> : null}
          </div>

          <div className={classes.item} onClick={() => onSelectItem(ESidebarMenu.Users)}>
            <UserIcon className={isUsersPage ? classes.activeStroke : null} />
            {isUsersPage ? <ActiveBarIcon className={classes.activeBar} /> : null}
          </div>
        </div>
      </div>
    </ReactProSidebar>
  );
});

export default Sidebar;
