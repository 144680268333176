import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import TokenService from './token_service';
import qs from 'qs';
import AuthService from './auth_service';
import ApiRoutes from 'configs/apiRoutes';
import UserService from './user_service';
import { EStatusCode } from 'configs/enums';
import { routes } from 'routers/routes';

let isRefreshingToken = false;
let pendingRequests: ((token: string) => void)[] = [];

const getToken = (): string => {
  return 'Bearer ' + TokenService.getToken();
};

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
api.defaults.headers.common['Authorization'] = getToken();
api.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (
      (error?.response?.status === EStatusCode.Unauthorized || error?.response?.status === EStatusCode.AccessDenied) &&
      !Object.values(ApiRoutes.auth)?.includes(error?.response?.config?.url)
    ) {
      if (!isRefreshingToken) {
        isRefreshingToken = true;
        POST(ApiRoutes.auth.refreshToken, {
          email: UserService.getUser().email,
          refresh_token: TokenService.getRefreshToken(),
        })
          .then((tokenResponse) => {
            AuthService.login(tokenResponse);
            pendingRequests?.forEach((request: (token: string) => void) => {
              request(getToken());
            });
          })
          .catch(() => {
            AuthService.logout();
            pendingRequests?.forEach((request: (token: string) => void) => request(null));
            window.location.href = routes.public.getStarted;
          })
          .finally(() => {
            isRefreshingToken = false;
            pendingRequests = [];
          });
      }

      return new Promise((resolve, reject) => {
        pendingRequests?.push((token: string) => {
          if (token) {
            error.config.headers.Authorization = token;
            resolve(axios(error?.config));
          }
          reject(error);
        });
      });
    }

    return Promise.reject(error);
  }
);

const GET = async (url: string, data: any = {}, config: AxiosRequestConfig = {}): Promise<any> => {
  api.defaults.headers.common['Authorization'] = getToken();
  return await api
    .get(url, {
      ...config,
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'brackets' });
      },
    })
    .then((response: AxiosResponse) => {
      return response?.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

const POST = async (url: string, data: any = {}, config: AxiosRequestConfig = {}): Promise<any> => {
  api.defaults.headers.common['Authorization'] = getToken();
  return await api
    .post(url, data, { ...config })
    .then((response: AxiosResponse) => {
      return response?.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

const POST_FORM_DATA = async (url: string, data: any = {}, config: AxiosRequestConfig = {}): Promise<any> => {
  api.defaults.headers.common['Authorization'] = getToken();
  return await api
    .post(url, data, { ...config })
    .then((response: AxiosResponse) => {
      return response?.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

const PATCH = async (url: string, data: any = {}, queryParams: any = {}, config: AxiosRequestConfig = {}): Promise<any> => {
  api.defaults.headers.common['Authorization'] = getToken();

  const urlWithParams = `${url}${Object.keys(queryParams).length > 0 ? '?' : ''}${new URLSearchParams(queryParams)}`;

  return await api
    .patch(urlWithParams, data, { ...config })
    .then((response: AxiosResponse) => {
      return response?.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

const PUT = async (url: string, data: any = {}, config: AxiosRequestConfig = {}): Promise<any> => {
  api.defaults.headers.common['Authorization'] = getToken();
  return api
    .put(url, data, { ...config })
    .then((response: AxiosResponse) => {
      return response?.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

const DELETE = async (url: string, data: any = {}, config: AxiosRequestConfig = {}): Promise<any> => {
  api.defaults.headers.common['Authorization'] = getToken();
  return await api
    .delete(url, {
      ...config,
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'brackets' });
      },
    })
    .then((response: AxiosResponse) => {
      return response?.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

const ApiService = {
  GET,
  POST,
  POST_FORM_DATA,
  PATCH,
  PUT,
  DELETE,
};

export default ApiService;
