import { Fragment, SyntheticEvent, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classes from './styles.module.scss';
import {
  BackIcon,
  CircleProgressIcon,
  CloseIcon,
  CopyIcon,
  DeleteIcon,
  EditIcon,
  ExpandIcon,
  FilterIcon,
  FireDefaultIcon,
  FireGreenIcon,
  FireRedIcon,
  FireYellowIcon,
  ImageIcon,
  SortDownDisabledIcon,
  SortUpIcon,
  UploadIcon,
  UploadImagesPlaceholderImage,
  ThumbnailPlaceholderImage,
  UserPlaceholderImage,
} from 'assets';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import clsx from 'clsx';
import Select from 'components/Select';
import { ECreateQueryString, EPriority, EProjectSettings, EResourceColumns, ESelectTheme, ESortDirection, EStatusCode, ETaskColumns } from 'configs/enums';
import Header from 'components/Header';
import { Container } from 'reactstrap';
import { Sidebar as ReactProSidebar } from 'react-pro-sidebar';
import Input from 'components/Input';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { ALL_OPTION, DATE_FORMAT, MAX_IMAGE_SIZE, NULL_VALUE, NULL_COLOR, PRIORITY_OPTIONS, SEARCH_DELAY } from 'configs/constant';
import { useDispatch, useSelector } from 'react-redux';
import { IReducer } from 'redux/reducers';
import { setProjectReducer, setPipelineReducer, setSortTaskTableReducer, setResourceTableReducer } from 'redux/reducers/Workspace/actionTypes';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DroppableProvided,
  DraggableProvided,
  DraggableStateSnapshot,
  DroppableStateSnapshot,
  DropResult,
} from 'react-beautiful-dnd';
import Status from './components/Status';
import DatePicker from './components/DatePicker';
import Budget from './components/Budget';
import Complexity from './components/Complexity';
import { IProject, IProjectUrlParams, ITaskConfigurationStatus } from 'interfaces/project';
import { useLocation, useParams } from 'react-router-dom';
import useRouteMatch from 'hooks/useRouteMatch';
import { routes } from 'routers/routes';
import {
  IDeleteResourceModal,
  IPipeline,
  IProgressItem,
  IResource,
  IResourceConfiguration,
  IResourceSidebar,
  IResourceSidebarFormData,
  ISubResourceTask,
  ISupervisor,
  IUpdateResourcePayload,
} from 'interfaces/pipeline';
import { push } from 'connected-react-router';
import { IBase64Image, ILightBox, ISelectOption } from 'interfaces/common';
import ApiService from 'services/api_service';
import ApiRoutes from 'configs/apiRoutes';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import ToastService from 'services/toast_service';
import Messages from 'configs/messages';
import {
  getResourceListRequest,
  getResourceSummaryRequest,
  setResourceListReducer,
  setResourceSummaryReducer,
} from 'redux/reducers/Information/actionTypes';
import { Pagination, Skeleton } from '@mui/material';
import QueryString from 'qs';
import ProjectService from 'services/project_service';
import StatusPopover from './components/Popovers/Status';
import PriorityPopover from './components/Popovers/Priority';
import ComplexityPopover from './components/Popovers/Complexity';
import UserPopover from './components/Popovers/User';
import dayjs from 'dayjs';
import CommonService from 'services/common_service';
import { ICollabUser } from 'interfaces/user';
import ModalDeleteResource from './components/ModalDeleteResource';
import DangerButton from 'components/Buttons/DangerButton';
import Lightbox from 'yet-another-react-lightbox';
import Counter from 'yet-another-react-lightbox/plugins/counter';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Download from 'yet-another-react-lightbox/plugins/download';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/counter.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import { ControllerRef } from 'yet-another-react-lightbox';
import Regexes from 'configs/regexes';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { getProjectCollabUserListRequest } from 'redux/reducers/User/actionTypes';
import styled from 'styled-components';
import ConfettiExplosion from 'react-confetti-explosion';
import { ActionMeta, GroupBase, IndicatorsContainerProps, OptionProps, ValueContainerProps, components } from 'react-select';
import useDebounce from 'hooks/useDebounce';
import Priority from './components/Priority';

const PriorityIcons = {
  [ALL_OPTION.value]: <div style={{ width: 21, height: 20 }} />,
  [EPriority.Highest]: <FireRedIcon />,
  [EPriority.High]: <FireYellowIcon />,
  [EPriority.Medium]: <FireGreenIcon />,
  [EPriority.Low]: <FireDefaultIcon />,
};

const CustomUsersOption = (props: OptionProps<unknown, boolean, GroupBase<unknown>>) => {
  const { getStyles, isDisabled, isFocused, isSelected, children, data, ...rest } = props;

  return (
    <components.Option {...rest} data={data} isDisabled={isDisabled} isFocused={isFocused} isSelected={isSelected} getStyles={getStyles}>
      <div className={classes.customOption}>
        <input type="checkbox" checked={isSelected} readOnly />
        <label />
        {(data as any)?.value === ALL_OPTION?.value ? null : <img src={(data as any)?.avatar ?? UserPlaceholderImage} alt="Avatar" />}
        <p>{children}</p>
      </div>
    </components.Option>
  );
};

const CustomUsersIndicatorsContainer = (props: IndicatorsContainerProps<unknown, boolean, GroupBase<unknown>>) => {
  const { children, ...rest } = props;

  return (
    <components.IndicatorsContainer {...rest}>
      {(children as any[])?.find((item) => item?.type?.name === 'DropdownIndicator')}
    </components.IndicatorsContainer>
  );
};

const CustomUsersValueContainer = (props: ValueContainerProps<unknown, boolean, GroupBase<unknown>>) => {
  const { children, hasValue, getValue } = props;

  if (!hasValue) {
    return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
  }

  const values = getValue()?.map((item: any) => item?.label);

  return (
    <components.ValueContainer {...props} className={classes.customValueContainer}>
      <p>Users: {`${values?.length === 1 ? values : values?.join(', ')}`}</p>
      {children?.[1]}
    </components.ValueContainer>
  );
};

const CustomPriorityOption = (props: OptionProps<unknown, boolean, GroupBase<unknown>>) => {
  const { getStyles, isDisabled, isFocused, isSelected, children, data, ...rest } = props;

  return (
    <components.Option {...rest} data={data} isDisabled={isDisabled} isFocused={isFocused} isSelected={isSelected} getStyles={getStyles}>
      <div className={classes.customOption}>
        {(data as any)?.leading}
        <p>{children}</p>
      </div>
    </components.Option>
  );
};

const CustomPriorityValueContainer = (props: ValueContainerProps<unknown, boolean, GroupBase<unknown>>) => {
  const { children, hasValue, getValue } = props;

  if (!hasValue) {
    return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
  }
  const values = getValue()
    ?.map((item: any) => item?.value)
    ?.join('');

  const getPriority = () => {
    switch (+values) {
      case EPriority.Highest:
        return (
          <Fragment>
            <FireRedIcon />
            <p>Highest</p>
          </Fragment>
        );
      case EPriority.High:
        return (
          <Fragment>
            <FireYellowIcon />
            <p>High</p>
          </Fragment>
        );
      case EPriority.Medium:
        return (
          <Fragment>
            <FireGreenIcon />
            <p>Medium</p>
          </Fragment>
        );
      case EPriority.Low:
        return (
          <Fragment>
            <FireDefaultIcon />
            <p>Low</p>
          </Fragment>
        );
      default:
        return <p>Priority: All</p>;
    }
  };

  return (
    <components.ValueContainer {...props} className={classes.customValueContainer}>
      <div>{getPriority()}</div>
      {children?.[1]}
    </components.ValueContainer>
  );
};

const CustomStatusValueContainer = (props: ValueContainerProps<unknown, boolean, GroupBase<unknown>>) => {
  const { children, hasValue, getValue } = props;

  if (!hasValue) {
    return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
  }

  const values = getValue()?.map((item: any) => item?.label);

  return (
    <components.ValueContainer {...props} className={classes.customValueContainer}>
      <p>Status: {values ?? 'N/A'}</p>
      {children?.[1]}
    </components.ValueContainer>
  );
};

const reorder = (list: ETaskColumns[], startIndex: number, endIndex: number) => {
  const taskColumns: ETaskColumns[] = Array.from(list);
  const [removed] = taskColumns.splice(startIndex, 1);
  taskColumns?.splice(endIndex, 0, removed);
  ProjectService.setTaskColumns(taskColumns);
  return taskColumns;
};

const resetTranslateY = (input: string) => {
  const regex = /translate\(([-\d.]+px),\s*([-\d.]+px)\)/;
  const match = input?.match(regex);
  if (match) {
    return `translate(${match?.[1]}, 0px)`;
  }
  return input;
};

const StatusDiv = styled.div<{ $color?: string }>`
  > div {
    border: 2px solid ${(props) => (props?.$color ? `#${props?.$color}` : 'var(--gray)')};
    > div {
      &::before {
        background: ${(props) => (props?.$color ? `#${props?.$color}` : 'var(--gray)')};
      }
    }
  }
`;

interface IQueryString {
  resourceId?: string;
  create?: ECreateQueryString;
}

interface ProjectPageProps {}

const ProjectPage: React.FC<ProjectPageProps> = memo((props: ProjectPageProps) => {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const { pathname, search } = useLocation();
  const { projectId, pipelineId } = useParams<IProjectUrlParams>();
  const { isExact: isProjectTaskViewPage } = useRouteMatch(routes.private.project.taskView);

  const { create, resourceId }: IQueryString = QueryString.parse(window?.location?.search);

  const { projectCollabUserList, sortProjectUserTable, user: userRedux } = useSelector((state: IReducer) => state.user);
  const { workspace, project, pipeline, resourceTable, sortTaskTable } = useSelector((state: IReducer) => state.workspace);
  const { projectList, pipelineList, resourceList, resourceSummary } = useSelector((state: IReducer) => state.information);

  const [resourceSidebar, setResourceSidebar] = useState<IResourceSidebar>({ isOpen: false, data: null });
  const [nameList, setNameList] = useState<string[]>([]);
  const [nameSticky, setNameSticky] = useState<number>(null);
  const [prioritySticky, setPrioritySticky] = useState<number>(null);
  const [complexitySticky, setComplexitySticky] = useState<number>(null);
  const [stageWidth, setStageWidth] = useState<number>(null);
  const [emptyBodyWidth, setEmptyBodyWidth] = useState<number>(null);
  const [taskColumns, setTaskColumns] = useState<ETaskColumns[]>(ProjectService.getTaskColumns() ?? Object.values(ETaskColumns));
  const [hoverRow, setHoverRow] = useState<number>(null);
  const [pipelineOptions, setPipelineOptions] = useState<ISelectOption[]>([]);
  const [editResource, setEditResource] = useState<IResource>(null);
  const [statusPopoverAnchorElement, setStatusPopoverAnchorElement] = useState<HTMLElement>(null);
  const [userPopoverAnchorElement, setUserPopoverAnchorElement] = useState<HTMLElement>(null);
  const [priorityPopoverAnchorElement, setPriorityPopoverAnchorElement] = useState<HTMLElement>(null);
  const [complexityPopoverAnchorElement, setComplexityPopoverAnchorElement] = useState<HTMLElement>(null);
  const [resourceImageList, setResourceImageList] = useState<IBase64Image[]>([]);
  const [modalDeleteResource, setModalDeleteResource] = useState<IDeleteResourceModal>({ isOpen: false, data: null });
  const [lightBox, setLightBox] = useState<ILightBox>({ isOpen: false, activeIndex: 0, data: null });
  const [isShowAnimation, setIsShowAnimation] = useState<boolean>(null);
  const [statusList, setStatusList] = useState<ITaskConfigurationStatus[]>(null);
  const [isShowFilters, setIsShowFilters] = useState<boolean>(true);

  const headRef = useRef<HTMLDivElement>();
  const headThumbnailRef = useRef<HTMLDivElement>();
  const headNameRef = useRef<HTMLDivElement>();
  const headPriorityRef = useRef<HTMLDivElement>();
  const firstSubResourceRef = useRef<HTMLDivElement>();
  const shouldFetchResourceTable = useRef<boolean>();
  const shouldContinueFetchResourceTable = useRef<boolean>();
  const updateResourcePayload = useRef<IUpdateResourcePayload>();
  const imageInputRef = useRef<HTMLInputElement>();
  const lightboxRef = useRef<ControllerRef>();

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      resourceName: nameList?.length
        ? Yup.string().matches(Regexes.configurationName, 'Only alphanumeric characters, underscores, and dashes are allowed.')
        : Yup.string()
            .required('This field is required.')
            .matches(Regexes.configurationName, 'Only alphanumeric characters, underscores, and dashes are allowed.'),
      selectedPipeline: Yup.object().required('This field is required.'),
    });
  }, [nameList]);

  const {
    register,
    getValues,
    setValue,
    setFocus,
    trigger,
    setError,
    clearErrors,
    watch,
    reset,
    formState: { errors },
  } = useForm<IResourceSidebarFormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const selectedPipeline = watch('selectedPipeline');

  // handle table responsive
  useEffect(() => {
    if (width) {
      setEmptyBodyWidth(headRef?.current?.offsetWidth);
    }
  }, [width, headRef?.current?.offsetWidth]);

  // handle table responsive
  useEffect(() => {
    if (width) {
      setStageWidth(firstSubResourceRef?.current?.offsetWidth);
    }
  }, [width, firstSubResourceRef?.current?.offsetWidth]);

  // handle table responsive
  useEffect(() => {
    if (width) {
      setNameSticky(headThumbnailRef?.current?.offsetWidth);
    }
  }, [width, headThumbnailRef?.current?.offsetWidth]);

  // handle table responsive
  useEffect(() => {
    if (width) {
      setPrioritySticky((headThumbnailRef?.current?.offsetWidth ?? 0) + (headNameRef?.current?.offsetWidth ?? 0));
    }
  }, [width, headThumbnailRef?.current?.offsetWidth, headNameRef?.current?.offsetWidth]);

  // handle table responsive
  useEffect(() => {
    if (width) {
      setComplexitySticky(
        (headThumbnailRef?.current?.offsetWidth ?? 0) + (headNameRef?.current?.offsetWidth ?? 0) + (headPriorityRef?.current?.offsetWidth ?? 0)
      );
    }
  }, [width, headThumbnailRef?.current?.offsetWidth, headNameRef?.current?.offsetWidth, headPriorityRef?.current?.offsetWidth]);

  useEffect(() => {
    if (create === ECreateQueryString.Resource) {
      setResourceSidebar({ isOpen: true, data: null });
    }
  }, [create]);

  useEffect(() => {
    if (resourceSummary?.data?.progress === 1 && isShowAnimation === null) {
      setIsShowAnimation(true);
    }
    if (lightBox?.isOpen) {
      const imageUrls = resourceSummary?.data?.title_image_urls;
      const lightBoxState = lightboxRef?.current?.getLightboxState();

      if (imageUrls?.length > 0) {
        setLightBox({
          ...lightBox,
          data: imageUrls?.map((url: string) => {
            return { src: url };
          }),
          activeIndex: lightBoxState?.currentIndex < imageUrls?.length ? lightBoxState?.currentIndex : lightBoxState?.currentIndex - 1,
        });
      } else {
        lightboxRef?.current?.close();
      }
    }
  }, [resourceSummary]);

  useEffect(() => {
    if (resourceImageList?.length) {
      onSubmitImage();
    }
  }, [resourceImageList]);

  useEffect(() => {
    if (pipelineList?.data) {
      setPipelineOptions(
        pipelineList?.data?.map((pipeline: IPipeline) => {
          return {
            value: pipeline?._id,
            label: pipeline?.name,
          };
        })
      );
    }
  }, [pipelineList]);

  useEffect(() => {
    if (!resourceList?.isLoading && resourceList?.data) {
      shouldFetchResourceTable.current = false;
    }
  }, [resourceList]);

  useEffect(() => {
    if (resourceSidebar?.isOpen) {
      setFocus('resourceName');
    } else {
      setValue('resourceName', '');
      setNameList([]);
      clearErrors();
    }
  }, [resourceSidebar]);

  useEffect(() => {
    if (projectCollabUserList?.projectId !== projectId) {
      dispatch(getProjectCollabUserListRequest(projectId, sortProjectUserTable?.column, sortProjectUserTable?.direction));
    }
  }, [projectId, projectCollabUserList]);

  useEffect(() => {
    if (resourceSidebar?.isOpen && create !== ECreateQueryString.Resource) {
      setResourceSidebar({ isOpen: false, data: null });
    }
  }, [project, create]);

  useEffect(() => {
    if (pipeline?.inherit_parent_task_configuration) {
      setStatusList(project?.task_configuration?.statuses);
    } else {
      setStatusList(pipeline?.task_configuration?.statuses);
    }
  }, [project, pipeline]);

  useEffect(() => {
    if (pipelineId !== NULL_VALUE) {
      if (pipelineOptions?.length) {
        setValue(
          'selectedPipeline',
          pipelineOptions?.find((item: ISelectOption) => item?.value === pipelineId)
        );
      }
    } else {
      reset();
    }
  }, [pipelineOptions, pipelineId]);

  useEffect(() => {
    if (projectId && projectList?.length) {
      dispatch(setProjectReducer(projectList?.find((project: IProject) => project?._id === projectId)));
    }
  }, [projectId, projectList]);

  useEffect(() => {
    if (resourceId) {
      if (resourceList?.data?.length) {
        if (resourceId !== NULL_VALUE) {
          const resource = resourceList?.data?.find((resource: IResource) => resource?._id === resourceId);
          if (resource) {
            setEditResource(resource);
          } else {
            dispatch(push({ pathname, search: `&resourceId=${resourceList?.data?.[0]?._id ?? NULL_VALUE}&workspaceId=${workspace?._id ?? NULL_VALUE}` }));
          }
        } else {
          dispatch(push({ pathname, search: `&resourceId=${resourceList?.data?.[0]?._id ?? NULL_VALUE}&workspaceId=${workspace?._id ?? NULL_VALUE}` }));
        }
      }
    } else {
      dispatch(
        push({
          pathname,
          search: `&resourceId=${resourceList?.projectId === projectId ? resourceList?.data?.[0]?._id ?? NULL_VALUE : NULL_VALUE}&workspaceId=${
            workspace?._id ?? NULL_VALUE
          }`,
        })
      );
    }
  }, [resourceId, resourceList]);

  useEffect(() => {
    if (!resourceSummary?.isLoading && resourceSummary?.data?._id !== editResource?._id && editResource?._id && isProjectTaskViewPage) {
      dispatch(getResourceSummaryRequest(editResource));
      setIsShowAnimation(null);
    }
  }, [resourceSummary, editResource, isProjectTaskViewPage]);

  useEffect(() => {
    if (pipelineId && pipelineId !== NULL_VALUE) {
      if (
        projectId &&
        resourceTable &&
        (!resourceList?.isLoading || shouldContinueFetchResourceTable?.current) &&
        (resourceList?.projectId !== projectId || resourceList?.pipelineId !== pipelineId || shouldFetchResourceTable?.current)
      ) {
        if (shouldContinueFetchResourceTable?.current) {
          shouldContinueFetchResourceTable.current = false;
        }
        dispatch(
          getResourceListRequest(
            projectId,
            pipelineId,
            resourceTable?.searchKey,
            resourceTable?.priority,
            resourceTable?.users,
            resourceTable?.status,
            resourceTable?.page,
            resourceTable?.limit,
            resourceTable?.totalPages,
            resourceTable?.column,
            resourceTable?.direction,
            false,
            resourceList?.resourceConfigurations
          )
        );
      }
    } else {
      if (resourceList?.projectId !== projectId) {
        dispatch(setResourceListReducer({ projectId, pipelineId: null, data: [], isLoading: false, resourceConfigurations: [], isPublished: true }));
      }
    }
  }, [projectId, pipelineId, resourceTable, resourceList]);

  useEffect(() => {
    if (pipelineId === NULL_VALUE) {
      if (projectId === pipelineList?.projectId && pipelineList?.data?.length) {
        dispatch(
          push({
            pathname: pathname?.replace(NULL_VALUE, pipelineList?.data?.[0]?._id),
            search,
          })
        );
      } else {
        dispatch(setPipelineReducer(null));
      }
    } else {
      if (pipelineId && pipelineList?.data?.length) {
        dispatch(setPipelineReducer(pipelineList?.data?.find((pipeline: IPipeline) => pipeline?._id === pipelineId)));
      }
    }
  }, [projectId, pipelineId, pipelineList, pathname, NULL_VALUE]);

  const renderFilters = () => {
    return (
      <div className={clsx(classes.filterContainer, { 'mb-0': !isProjectTaskViewPage })}>
        <Select
          customTheme={ESelectTheme.Filter}
          placeholder="Priority"
          value={resourceTable?.priority}
          options={PRIORITY_OPTIONS?.map((option) => ({ ...option, leading: PriorityIcons?.[option?.value] }))}
          onChange={(option: any) => {
            shouldFetchResourceTable.current = true;
            if (resourceList?.isLoading) {
              shouldContinueFetchResourceTable.current = true;
            }
            dispatch(setResourceTableReducer({ ...resourceTable, priority: option }));
          }}
          components={{
            Option: CustomPriorityOption,
            ValueContainer: CustomPriorityValueContainer,
          }}
        />

        {projectCollabUserList?.projectId === projectId ? (
          <Select
            customTheme={ESelectTheme.Filter}
            placeholder="User"
            isMulti={true}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            value={resourceTable?.users}
            options={[
              ALL_OPTION,
              { value: userRedux?._id, label: CommonService.getFullName(userRedux), avatar: userRedux?.profile_image_url },
              ...(projectCollabUserList?.data
                ?.slice()
                ?.sort((a: ICollabUser, b: ICollabUser) => {
                  const nameA = a?.first_name?.toUpperCase();
                  const nameB = b?.first_name?.toUpperCase();
                  if (nameA < nameB) {
                    return -1;
                  }
                  if (nameA > nameB) {
                    return 1;
                  }
                  return 0;
                })
                ?.map((user: ICollabUser) => ({ value: user?._id, label: CommonService.getFullName(user), avatar: user?.profile_image_url })) ?? []),
            ]}
            onChange={(selectedOptions: any, actionMeta: ActionMeta<any>) => {
              shouldFetchResourceTable.current = true;
              if (resourceList?.isLoading) {
                shouldContinueFetchResourceTable.current = true;
              }
              dispatch(
                setResourceTableReducer({
                  ...resourceTable,
                  users:
                    actionMeta?.option?.value === ALL_OPTION?.value
                      ? [ALL_OPTION]
                      : selectedOptions?.filter((item: any) => item?.value !== ALL_OPTION?.value),
                })
              );
            }}
            components={{
              Option: CustomUsersOption,
              IndicatorsContainer: CustomUsersIndicatorsContainer,
              ValueContainer: CustomUsersValueContainer,
            }}
          />
        ) : (
          <Skeleton variant="rectangular" width={150} height={38} sx={{ borderRadius: '16px', background: 'var(--backgroundLight)' }} />
        )}

        {statusList ? (
          <Select
            customTheme={ESelectTheme.Filter}
            placeholder="Status"
            value={resourceTable?.status}
            options={[
              ALL_OPTION,
              ...(statusList?.map((status: ITaskConfigurationStatus) => {
                return { value: status?.name, label: status?.name };
              }) ?? []),
            ]}
            onChange={(option: any) => {
              shouldFetchResourceTable.current = true;
              if (resourceList?.isLoading) {
                shouldContinueFetchResourceTable.current = true;
              }
              dispatch(setResourceTableReducer({ ...resourceTable, status: option }));
            }}
            components={{
              ValueContainer: CustomStatusValueContainer,
            }}
          />
        ) : (
          <Skeleton variant="rectangular" width={150} height={38} sx={{ borderRadius: '16px', background: 'var(--backgroundLight)' }} />
        )}
      </div>
    );
  };

  const renderTableFooter = () => {
    return (
      <div className={classes.tableFooter}>
        <Pagination
          count={resourceTable?.totalPages}
          page={resourceTable?.page}
          onChange={(_: any, page: number) => {
            shouldFetchResourceTable.current = true;
            if (resourceList?.isLoading) {
              shouldContinueFetchResourceTable.current = true;
            }
            dispatch(setResourceTableReducer({ ...resourceTable, page }));
          }}
          className={classes.pagination}
        />
      </div>
    );
  };

  const renderHeadColumn = (input: ETaskColumns) => {
    switch (input) {
      case ETaskColumns.Stage:
        return (
          <div
            onClick={() => {
              dispatch(
                setSortTaskTableReducer({
                  column: ETaskColumns.Stage,
                  direction:
                    sortTaskTable?.column === ETaskColumns.Stage
                      ? sortTaskTable?.direction === ESortDirection.Ascending
                        ? ESortDirection.Descending
                        : sortTaskTable?.direction === ESortDirection.Descending
                        ? null
                        : ESortDirection.Ascending
                      : ESortDirection.Ascending,
                })
              );
            }}
          >
            <p>Stage</p>
            {sortTaskTable?.direction ? (
              sortTaskTable?.column === ETaskColumns.Stage ? (
                <SortUpIcon className={clsx({ [classes.revert]: sortTaskTable?.direction === ESortDirection.Ascending })} />
              ) : (
                <SortDownDisabledIcon />
              )
            ) : null}
          </div>
        );
      case ETaskColumns.Status:
        return 'Status';
      case ETaskColumns.Assigned:
        return 'Assigned';
      case ETaskColumns.StartDate:
        return 'Start Date';
      case ETaskColumns.DueDate:
        return 'Due Date';
      case ETaskColumns.Duration:
        return 'Duration';
      case ETaskColumns.Budget:
        return 'Budget';
      // case ETaskColumns.Complexity:
      //   return (
      //     <div
      //       onClick={() => {
      //         dispatch(
      //           setSortTaskTableReducer({
      //             column: ETaskColumns.Complexity,
      //             direction:
      //               sortTaskTable?.column === ETaskColumns.Complexity
      //                 ? sortTaskTable?.direction === ESortDirection.Ascending
      //                   ? ESortDirection.Descending
      //                   : ESortDirection.Ascending
      //                 : ESortDirection.Ascending,
      //           })
      //         );
      //       }}
      //     >
      //       <p>Complexity</p>
      //       {sortTaskTable?.column === ETaskColumns.Complexity ? (
      //         <SortUpIcon className={clsx({ [classes.revert]: sortTaskTable?.direction === ESortDirection.Ascending })} />
      //       ) : (
      //         <SortDownDisabledIcon />
      //       )}
      //     </div>
      //   );
      // case ETaskColumns.Dependencies:
      //   return 'Dependencies';
      default:
        return input;
    }
  };

  const renderColumn = (column: ETaskColumns, resourceConfiguration: IResourceConfiguration, resource: IResource, subResourceTask?: ISubResourceTask) => {
    switch (column) {
      case ETaskColumns.Stage:
        return <p className={classes.stage}>{resourceConfiguration?.name || '-'}</p>;
      case ETaskColumns.Assigned:
        return (
          <div
            id={`assigned-${column}-${resourceConfiguration?._id}`}
            className={classes.assignedContainer}
            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
              updateResourcePayload.current = {
                id: resource?._id,
                name: resourceConfiguration?.name,
                task: subResourceTask,
              };
              onClickUserPopover(event);
            }}
          >
            <img src={subResourceTask?.assigned_user?.profile_image_url ?? UserPlaceholderImage} alt="Avatar" />
            <p>{CommonService.getFullName(subResourceTask?.assigned_user, '-')}</p>
          </div>
        );
      case ETaskColumns.Status:
        return (
          <Status
            id={`status-${column}-${resourceConfiguration?._id}`}
            status={subResourceTask?.status}
            color={subResourceTask?.status_color}
            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
              updateResourcePayload.current = {
                id: resource?._id,
                name: resourceConfiguration?.name,
                task: subResourceTask,
              };
              onClickStatusPopover(event);
            }}
          />
        );
      case ETaskColumns.StartDate:
        return (
          <DatePicker
            date={subResourceTask?.start_date_time}
            payload={{
              id: resource?._id,
              name: resourceConfiguration?.name,
              task: subResourceTask,
            }}
          />
        );
      case ETaskColumns.DueDate:
        return (
          <DatePicker
            date={subResourceTask?.end_date_time}
            isEndDate={true}
            payload={{
              id: resource?._id,
              name: resourceConfiguration?.name,
              task: subResourceTask,
            }}
          />
        );
      case ETaskColumns.Duration: {
        if (!subResourceTask?.end_date_time || !subResourceTask?.start_date_time) {
          return '-';
        }
        const duration = dayjs(subResourceTask?.end_date_time)?.diff(dayjs(subResourceTask?.start_date_time), 'day');
        // use != detect both null and undefined
        return (
          <p className={classes.duration}>{duration != null ? `${duration} day${duration === 1 || duration === -1 || duration === 0 ? '' : 's'}` : '-'}</p>
        );
      }
      case ETaskColumns.Budget: {
        if (!subResourceTask?.end_date_time || !subResourceTask?.start_date_time) {
          return '-';
        }

        const today = dayjs();
        const startDate = dayjs(subResourceTask?.start_date_time);
        const endDate = dayjs(subResourceTask?.end_date_time);
        const totalDuration = endDate.diff(startDate, 'day');
        // use == detect both null and undefined
        if (totalDuration == null) {
          return '-';
        }

        const elapsedDuration = today?.diff(startDate, 'day');
        const result = totalDuration === 0 ? 100 : Math.round((elapsedDuration / totalDuration) * 100);
        return <Budget budget={result} isCompleted={subResourceTask?.is_complete} />;
      }
      // case ETaskColumns.Complexity:
      //   return (
      //     <Complexity
      //       id={`complexity-${column}-${resourceConfiguration?._id}`}
      //       complexityLevel={subResourceTask?.complexity}
      //       onClick={(event: React.MouseEvent<HTMLDivElement>) => {
      //         updateResourcePayload.current = {
      //           id: resource?._id,
      //           name: resourceConfiguration?.name,
      //           task: subResourceTask,
      //         };
      //         onClickComplexityPopover(event);
      //       }}
      //     />
      //   );
      // case ETaskColumns.Dependencies:
      //   return '-';
      default:
        return null;
    }
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    setTaskColumns(reorder(taskColumns, result?.source?.index, result?.destination?.index) as any);
  };

  const onAddName = (name: string) => {
    if (name) {
      if (!nameList?.includes(name)) {
        setNameList([...nameList, name]);
      }
      setValue('resourceName', '');
    }
  };

  const onSearchResourceList = useDebounce((event: React.ChangeEvent<HTMLInputElement>) => {
    shouldFetchResourceTable.current = true;
    if (resourceList?.isLoading) {
      shouldContinueFetchResourceTable.current = true;
    }
    dispatch(setResourceTableReducer({ ...resourceTable, searchKey: event?.target?.value }));
  }, SEARCH_DELAY);

  const onDeleteResource = () => {
    dispatch(setIsLoadingReducer(true));
    ApiService.DELETE(ApiRoutes.resource.info.replace(':resourceId', modalDeleteResource?.data?._id))
      .then(() => {
        dispatch(
          setResourceListReducer({
            ...resourceList,
            data: resourceList?.data?.filter((resource: IResource) => resource?._id !== modalDeleteResource?.data?._id),
          })
        );
        onCloseModals();
        ToastService.success(Messages.success.deleted);
      })
      .catch((error) => {
        if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
          ToastService.error(Messages.error.accessDenied);
          return;
        }
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const onCreateResource = () => {
    const resourceName = getValues('resourceName');

    const createPayload =
      !nameList?.length && resourceName
        ? { name: resourceName, project_id: projectId, pipeline_id: selectedPipeline?.value, category: 'prop', priority: 1 }
        : nameList?.map((name: string) => {
            return {
              name,
              project_id: projectId,
              pipeline_id: selectedPipeline?.value,
              category: 'prop',
              priority: 1,
            };
          });

    dispatch(setIsLoadingReducer(true));
    ApiService.POST(ApiRoutes.resource.default, createPayload)
      .then(() => {
        // remove "create=resource" search params after creating
        if (create === ECreateQueryString.Resource) {
          dispatch(
            push({
              pathname: routes.private.project.default.replace(':projectId', projectId).replace(':pipelineId', pipelineId ?? null),
              search: `&resourceId=${resourceId}&workspaceId=${workspace?._id ?? NULL_VALUE}`,
            })
          );
        }
        if (selectedPipeline?.value === pipelineId) {
          dispatch(
            getResourceListRequest(
              projectId,
              pipelineId,
              resourceTable?.searchKey,
              resourceTable?.priority,
              resourceTable?.users,
              resourceTable?.status,
              resourceTable?.page,
              resourceTable?.limit,
              resourceTable?.totalPages,
              resourceTable?.column,
              resourceTable?.direction,
              true
            )
          );
        } else {
          ToastService.success(
            Messages.success.resourceAdded
              .replace(':resource', (!nameList?.length && resourceName) || nameList?.length === 1 ? '1 resource' : `${nameList?.length} resources`)
              .replace(':pipelineName', selectedPipeline?.label as string)
          );
        }
        setResourceSidebar({ isOpen: false, data: null });
      })
      .catch((error) => {
        if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
          ToastService.error(Messages.error.accessDenied);
          return;
        }
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => {
        if (selectedPipeline?.value !== pipelineId) {
          dispatch(setIsLoadingReducer(false));
        }
      });
  };

  const onChangeImage = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    const result: IBase64Image[] = [];
    const imageFileList: FileList = event?.target?.files;

    const processImage = async (file: File): Promise<IBase64Image> => {
      if (file?.size >= MAX_IMAGE_SIZE) {
        ToastService.error(Messages.error.imageSize);
        return undefined;
      }
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (imageBase64: ProgressEvent<FileReader>) => {
          if (imageBase64?.target) {
            resolve({ content: imageBase64?.target?.result as string });
          }
        };
        reader?.readAsDataURL(file);
      });
    };

    for (const file of imageFileList) {
      const processedImage = await processImage(file);
      if (processedImage) {
        result?.push(processedImage);
      }
    }

    setResourceImageList(result);
  }, []);

  const onSubmitImage = () => {
    dispatch(setIsLoadingReducer(true));
    ApiService.POST(ApiRoutes.resource.image.upload.replace(':resourceId', resourceId), resourceImageList)
      .then((response) => {
        const updatedResource = response?.entities?.[0];
        dispatch(
          setResourceSummaryReducer({
            ...resourceSummary,
            data: { ...resourceSummary?.data, title_image_urls: updatedResource?.title_image_urls },
          })
        );

        dispatch(
          setResourceListReducer({
            ...resourceList,
            data: resourceList?.data?.map((resource: IResource) => {
              if (resource?._id === updatedResource?._id) {
                return updatedResource;
              }
              return resource;
            }),
          })
        );

        ToastService.success(Messages.success.default);
      })
      .catch((error) => {
        if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
          ToastService.error(Messages.error.accessDenied);
          return;
        }
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const onClickStatusPopover = (event: React.MouseEvent<HTMLDivElement>) => {
    onCloseUserPopover();
    onClosePriorityPopover();
    onCloseComplexityPopover();
    setStatusPopoverAnchorElement(event?.currentTarget?.id === statusPopoverAnchorElement?.id ? null : event?.currentTarget);
  };

  const onCloseStatusPopover = () => {
    setStatusPopoverAnchorElement(null);
  };

  const onClickUserPopover = (event: React.MouseEvent<HTMLDivElement>) => {
    onCloseStatusPopover();
    onClosePriorityPopover();
    onCloseComplexityPopover();
    setUserPopoverAnchorElement(event?.currentTarget?.id === userPopoverAnchorElement?.id ? null : event?.currentTarget);
  };

  const onCloseUserPopover = () => {
    setUserPopoverAnchorElement(null);
  };

  const onClickPriorityPopover = (event: React.MouseEvent<HTMLDivElement>) => {
    onCloseStatusPopover();
    onCloseUserPopover();
    onCloseComplexityPopover();
    setPriorityPopoverAnchorElement(event?.currentTarget?.id === priorityPopoverAnchorElement?.id ? null : event?.currentTarget);
  };

  const onClosePriorityPopover = () => {
    setPriorityPopoverAnchorElement(null);
  };

  const onClickComplexityPopover = (event: React.MouseEvent<HTMLDivElement>) => {
    onCloseStatusPopover();
    onCloseUserPopover();
    onClosePriorityPopover();
    setComplexityPopoverAnchorElement(event?.currentTarget?.id === complexityPopoverAnchorElement?.id ? null : event?.currentTarget);
  };

  const onCloseComplexityPopover = () => {
    setComplexityPopoverAnchorElement(null);
  };

  const onCloseModals = () => {
    setModalDeleteResource({ isOpen: false, data: null });
  };

  const onDeleteResourceImage = (imageUrl: string) => {
    dispatch(setIsLoadingReducer(true));
    ApiService.DELETE(ApiRoutes.resource.image.delete.replace(':resourceId', resourceId).replace(':imageUrl', imageUrl))
      .then((response) => {
        const updatedResource = response?.entities?.[0];

        dispatch(
          setResourceSummaryReducer({
            ...resourceSummary,
            data: { ...resourceSummary?.data, title_image_urls: updatedResource?.title_image_urls },
          })
        );

        dispatch(
          setResourceListReducer({
            ...resourceList,
            data: resourceList?.data?.map((resource: IResource) => {
              if (resource?._id === updatedResource?._id) {
                return updatedResource;
              }
              return resource;
            }),
          })
        );

        ToastService.success(Messages.success.deleted);
      })
      .catch((error) => {
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  return (
    <Container fluid style={{ overflowX: 'auto' }}>
      <Header setResourceSidebar={setResourceSidebar} />

      <div className={classes.container} style={{ minWidth: isProjectTaskViewPage ? 'fit-content' : '' }}>
        {!isProjectTaskViewPage ? (
          (resourceList?.isPublished && pipelineList?.data?.length) || !pipelineList?.data ? (
            <Fragment>
              <div className={classes.toolbar}>
                <div className={classes.toolbarLeft}>
                  <input
                    className={classes.searchInput}
                    type="text"
                    placeholder="Search"
                    defaultValue={resourceTable?.searchKey}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      onSearchResourceList(event);
                    }}
                  />
                  {renderFilters()}
                </div>

                <div className={classes.toolbarRight}>
                  {project ? (
                    <PrimaryButton onClick={() => setResourceSidebar({ isOpen: true, data: null })}>Add Item</PrimaryButton>
                  ) : (
                    <Skeleton variant="rectangular" width={101.7} height={37} sx={{ borderRadius: '4px', background: 'var(--backgroundLight)' }} />
                  )}
                  {pipeline || !pipelineList?.data?.length ? (
                    <div
                      className={classes.taskViewButton}
                      onClick={() =>
                        dispatch(
                          push({
                            pathname: routes.private.project.taskView
                              .replace(':projectId', projectId)
                              .replace(':pipelineId', !pipelineList?.data?.length ? null : pipeline?._id),
                            search: `&workspaceId=${workspace?._id ?? NULL_VALUE}`,
                          })
                        )
                      }
                    >
                      <p>Task view</p>
                      <ExpandIcon />
                    </div>
                  ) : (
                    <Skeleton variant="rectangular" width={129.49} height={37} sx={{ borderRadius: '4px', background: 'var(--backgroundLight)' }} />
                  )}
                </div>
              </div>

              <div className={classes.table}>
                {!pipelineList?.isLoading && pipelineList?.data && (resourceList?.data || shouldFetchResourceTable?.current) ? (
                  <div className={classes.head} id={`head-${pipeline?._id}`} ref={headRef}>
                    <div
                      id={`head-thumbnail-${pipeline?._id}`}
                      ref={headThumbnailRef}
                      className={clsx(classes.column, classes.thumbnail)}
                      style={{ left: 0 }}
                    />

                    <div
                      id={`head-name-${pipeline?._id}`}
                      ref={headNameRef}
                      className={clsx(classes.column, classes.name)}
                      style={{ left: nameSticky }}
                      onClick={() => {
                        shouldFetchResourceTable.current = true;
                        if (resourceList?.isLoading) {
                          shouldContinueFetchResourceTable.current = true;
                        }
                        dispatch(
                          setResourceTableReducer({
                            ...resourceTable,
                            column: EResourceColumns.Name,
                            direction:
                              resourceTable?.column === EResourceColumns.Name
                                ? resourceTable?.direction === ESortDirection.Ascending
                                  ? ESortDirection.Descending
                                  : ESortDirection.Ascending
                                : ESortDirection.Ascending,
                          })
                        );
                      }}
                    >
                      <div>
                        <p>Name</p>
                        {resourceTable?.column === EResourceColumns.Name ? (
                          <SortUpIcon className={clsx({ [classes.revert]: resourceTable?.direction === ESortDirection.Ascending })} />
                        ) : (
                          <SortDownDisabledIcon />
                        )}
                      </div>
                    </div>

                    <div
                      id={`head-priority-${pipeline?._id}`}
                      ref={headPriorityRef}
                      className={clsx(classes.column, classes.priority)}
                      style={{ left: prioritySticky }}
                      onClick={() => {
                        shouldFetchResourceTable.current = true;
                        if (resourceList?.isLoading) {
                          shouldContinueFetchResourceTable.current = true;
                        }
                        dispatch(
                          setResourceTableReducer({
                            ...resourceTable,
                            column: EResourceColumns.Priority,
                            direction:
                              resourceTable?.column === EResourceColumns.Priority
                                ? resourceTable?.direction === ESortDirection.Ascending
                                  ? ESortDirection.Descending
                                  : ESortDirection.Ascending
                                : ESortDirection.Ascending,
                          })
                        );
                      }}
                    >
                      <div>
                        <p>Priority</p>
                        {resourceTable?.column === EResourceColumns.Priority ? (
                          <SortUpIcon className={clsx({ [classes.revert]: resourceTable?.direction === ESortDirection.Ascending })} />
                        ) : (
                          <SortDownDisabledIcon />
                        )}
                      </div>
                    </div>

                    <div
                      className={clsx(classes.column, classes.complexity)}
                      style={{ left: complexitySticky }}
                      onClick={() => {
                        shouldFetchResourceTable.current = true;
                        if (resourceList?.isLoading) {
                          shouldContinueFetchResourceTable.current = true;
                        }
                        dispatch(
                          setResourceTableReducer({
                            ...resourceTable,
                            column: EResourceColumns.Complexity,
                            direction:
                              resourceTable?.column === EResourceColumns.Complexity
                                ? resourceTable?.direction === ESortDirection.Ascending
                                  ? ESortDirection.Descending
                                  : ESortDirection.Ascending
                                : ESortDirection.Ascending,
                          })
                        );
                      }}
                    >
                      <div>
                        <p>Complexity</p>
                        {resourceTable?.column === EResourceColumns.Complexity ? (
                          <SortUpIcon className={clsx({ [classes.revert]: resourceTable?.direction === ESortDirection.Ascending })} />
                        ) : (
                          <SortDownDisabledIcon />
                        )}
                      </div>
                    </div>

                    {resourceList?.resourceConfigurations?.map((resourceConfiguration: IResourceConfiguration) => {
                      return (
                        <div key={`resource-configuration-${resourceConfiguration?._id}`} className={classes.column} style={{ maxWidth: stageWidth }}>
                          {resourceConfiguration?.name}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <Skeleton variant="rectangular" width="100%" height={32} sx={{ borderRadius: '8px', background: 'var(--backgroundLight)' }} />
                )}

                {pipelineList?.data && resourceList?.data ? (
                  <div className={classes.bodyContainer}>
                    <div
                      className={clsx(classes.body, { [classes.empty]: !resourceList?.data?.length })}
                      style={!resourceList?.data?.length ? { minWidth: emptyBodyWidth } : null}
                    >
                      {resourceList?.data?.length ? (
                        resourceList?.data?.map((resource: IResource) => {
                          return (
                            <div key={`resource-${resource?._id}`} className={classes.row}>
                              <div className={clsx(classes.column, classes.thumbnail)}>
                                <img
                                  className={classes.imageThumbnail}
                                  src={
                                    resource?.title_image_urls?.length
                                      ? CommonService.getImageThumbnailUrl(resource?.title_image_urls?.slice(-1)?.[0])
                                      : ThumbnailPlaceholderImage
                                  }
                                  onError={(event: SyntheticEvent<HTMLImageElement, Event>) => {
                                    event.currentTarget.src = ThumbnailPlaceholderImage;
                                  }}
                                  loading="lazy"
                                  alt="Thumbnail"
                                />
                              </div>

                              <div
                                className={clsx(classes.column, classes.name)}
                                style={{ left: nameSticky }}
                                onClick={() =>
                                  dispatch(
                                    push({
                                      pathname: routes.private.project.taskView
                                        .replace(':projectId', projectId)
                                        .replace(':pipelineId', pipelineId ?? null),
                                      search: `&resourceId=${resource?._id ?? NULL_VALUE}&workspaceId=${workspace?._id ?? NULL_VALUE}`,
                                    })
                                  )
                                }
                              >
                                <EditIcon />
                                <p>{resource?.name ?? 'N/A'}</p>
                              </div>

                              <div
                                className={clsx(classes.column, classes.priority)}
                                style={{ left: prioritySticky }}
                                onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                                  updateResourcePayload.current = {
                                    id: resource?._id,
                                    name: null,
                                    task: null,
                                  };
                                  onClickPriorityPopover(event);
                                }}
                              >
                                <Priority level={resource?.priority} />
                              </div>

                              <div
                                className={clsx(classes.column, classes.complexity)}
                                style={{ left: complexitySticky }}
                                onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                                  updateResourcePayload.current = {
                                    id: resource?._id,
                                    name: null,
                                    task: null,
                                  };
                                  onClickComplexityPopover(event);
                                }}
                              >
                                <Complexity
                                  complexityLevel={resource?.complexity}
                                  onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                                    updateResourcePayload.current = {
                                      id: resource?._id,
                                      name: null,
                                      task: null,
                                    };
                                    onClickComplexityPopover(event);
                                  }}
                                />
                              </div>

                              {resourceList?.resourceConfigurations?.map((resourceConfiguration: IResourceConfiguration) => {
                                const subResourceTask: ISubResourceTask = resource?.sub_resource_tasks?.find(
                                  (subResourceTask: ISubResourceTask) => subResourceTask?.sub_resource_type === resourceConfiguration?.name
                                );
                                if (subResourceTask) {
                                  return (
                                    <StatusDiv
                                      key={`resource-configuration-${resource?._id}-${resourceConfiguration?._id}`}
                                      ref={firstSubResourceRef}
                                      className={clsx(classes.column, classes.status)}
                                      $color={subResourceTask?.status_color ?? NULL_COLOR}
                                    >
                                      <div
                                        id={`task-${resource?._id}-${resourceConfiguration?._id}`}
                                        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                                          event?.stopPropagation();
                                          updateResourcePayload.current = {
                                            id: resource?._id,
                                            name: resourceConfiguration?.name,
                                            task: subResourceTask,
                                          };
                                          onClickStatusPopover(event);
                                        }}
                                      >
                                        <p>{subResourceTask?.status ?? 'Not started'}</p>
                                        <div
                                          id={`user-${resource?._id}-${resourceConfiguration?._id}`}
                                          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                                            event?.stopPropagation();
                                            updateResourcePayload.current = {
                                              id: resource?._id,
                                              name: resourceConfiguration?.name,
                                              task: subResourceTask,
                                            };
                                            onClickUserPopover(event);
                                          }}
                                        >
                                          <img src={subResourceTask?.assigned_user?.profile_image_url ?? UserPlaceholderImage} alt="Avatar" />
                                        </div>
                                      </div>
                                    </StatusDiv>
                                  );
                                }
                                return (
                                  <div
                                    key={`resource-configuration-${resource?._id}-${resourceConfiguration?._id}`}
                                    className={clsx(classes.column, classes.status, classes.notStarted)}
                                    ref={firstSubResourceRef}
                                  >
                                    <div
                                      id={`task-${resource?._id}-${resourceConfiguration?._id}`}
                                      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                                        event?.stopPropagation();
                                        updateResourcePayload.current = {
                                          id: resource?._id,
                                          name: resourceConfiguration?.name,
                                          task: null,
                                        };
                                        onClickStatusPopover(event);
                                      }}
                                    >
                                      <p>Not started</p>
                                      <div
                                        id={`user-${resource?._id}-${resourceConfiguration?._id}`}
                                        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                                          event?.stopPropagation();
                                          updateResourcePayload.current = {
                                            id: resource?._id,
                                            name: resourceConfiguration?.name,
                                            task: null,
                                          };
                                          onClickUserPopover(event);
                                        }}
                                      >
                                        <img src={UserPlaceholderImage} alt="Avatar" />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })
                      ) : (
                        <p>No resources found.</p>
                      )}
                    </div>
                  </div>
                ) : (
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height="calc(100vh - 24px - 40px - 24px - 38px - 24px - 32px - 56px - 40px - 20px)"
                    sx={{
                      minHeight: '500px',
                      minWidth: headRef?.current?.offsetWidth ?? '100%',
                      borderRadius: '8px 8px 0 0',
                      background: 'var(--backgroundLight)',
                    }}
                  />
                )}
              </div>

              {renderTableFooter()}
            </Fragment>
          ) : (
            <div className={classes.noPublishedPipeline}>
              <p>Unpublished pipeline</p>
              <span>The pipeline must first be published before creating resources.</span>
              <PrimaryButton
                onClick={() =>
                  dispatch(
                    push({
                      pathname: routes.private.project.edit.replace(':workspaceId', workspace?._id).replace(':projectId', project?._id),
                      search: `&name=${encodeURIComponent(project?.name)}&type=${EProjectSettings.Pipeline}&activePipelineId=${
                        pipeline?._id ?? NULL_VALUE
                      }&workspaceId=${workspace?._id ?? NULL_VALUE}`,
                    })
                  )
                }
              >
                Next
              </PrimaryButton>
            </div>
          )
        ) : (resourceList?.isPublished && pipelineList?.data?.length) || !pipelineList?.data ? (
          <div className={classes.layout}>
            <div className={classes.left}>
              <div className={classes.leftHeader}>
                <input className={clsx(classes.searchInput, 'w-100')} type="text" placeholder="Search" />
                <FilterIcon onClick={() => setIsShowFilters(!isShowFilters)} />
              </div>

              {isShowFilters ? renderFilters() : null}

              <div className={classes.leftTable}>
                <div className={classes.head}>
                  <div className={clsx(classes.column, classes.thumbnail)} />

                  <div
                    className={clsx(classes.column, classes.name)}
                    onClick={() => {
                      shouldFetchResourceTable.current = true;
                      if (resourceList?.isLoading) {
                        shouldContinueFetchResourceTable.current = true;
                      }
                      dispatch(
                        setResourceTableReducer({
                          ...resourceTable,
                          column: EResourceColumns.Name,
                          direction:
                            resourceTable?.column === EResourceColumns.Name
                              ? resourceTable?.direction === ESortDirection.Ascending
                                ? ESortDirection.Descending
                                : ESortDirection.Ascending
                              : ESortDirection.Ascending,
                        })
                      );
                    }}
                  >
                    <div>
                      <p>Name</p>
                      {resourceTable?.column === EResourceColumns.Name ? (
                        <SortUpIcon className={clsx({ [classes.revert]: resourceTable?.direction === ESortDirection.Ascending })} />
                      ) : (
                        <SortDownDisabledIcon />
                      )}
                    </div>
                  </div>

                  <div
                    className={clsx(classes.column, classes.priority)}
                    onClick={() => {
                      shouldFetchResourceTable.current = true;
                      if (resourceList?.isLoading) {
                        shouldContinueFetchResourceTable.current = true;
                      }
                      dispatch(
                        setResourceTableReducer({
                          ...resourceTable,
                          column: EResourceColumns.Priority,
                          direction:
                            resourceTable?.column === EResourceColumns.Priority
                              ? resourceTable?.direction === ESortDirection.Ascending
                                ? ESortDirection.Descending
                                : ESortDirection.Ascending
                              : ESortDirection.Ascending,
                        })
                      );
                    }}
                  >
                    <div>
                      <p>Priority</p>
                      {resourceTable?.column === EResourceColumns.Priority ? (
                        <SortUpIcon className={clsx({ [classes.revert]: resourceTable?.direction === ESortDirection.Ascending })} />
                      ) : (
                        <SortDownDisabledIcon />
                      )}
                    </div>
                  </div>

                  <div
                    className={clsx(classes.column, classes.complexity)}
                    onClick={() => {
                      shouldFetchResourceTable.current = true;
                      if (resourceList?.isLoading) {
                        shouldContinueFetchResourceTable.current = true;
                      }
                      dispatch(
                        setResourceTableReducer({
                          ...resourceTable,
                          column: EResourceColumns.Complexity,
                          direction:
                            resourceTable?.column === EResourceColumns.Complexity
                              ? resourceTable?.direction === ESortDirection.Ascending
                                ? ESortDirection.Descending
                                : ESortDirection.Ascending
                              : ESortDirection.Ascending,
                        })
                      );
                    }}
                  >
                    <div>
                      <p>Complexity</p>
                      {resourceTable?.column === EResourceColumns.Complexity ? (
                        <SortUpIcon className={clsx({ [classes.revert]: resourceTable?.direction === ESortDirection.Ascending })} />
                      ) : (
                        <SortDownDisabledIcon />
                      )}
                    </div>
                  </div>
                </div>

                {pipelineList?.data && resourceList?.data ? (
                  <div className={clsx(classes.bodyContainer, { [classes.hasFilters]: isShowFilters })}>
                    <div className={clsx(classes.body, { [classes.empty]: !resourceList?.data?.length })}>
                      {resourceList?.data?.length ? (
                        resourceList?.data?.map((resource: IResource) => {
                          return (
                            <div
                              key={`resource-${resource?._id}`}
                              className={clsx(classes.row, { [classes.active]: editResource?._id === resource?._id })}
                            >
                              <div className={clsx(classes.column, classes.thumbnail)}>
                                <img
                                  className={classes.imageThumbnail}
                                  src={
                                    resource?.title_image_urls?.length
                                      ? CommonService.getImageThumbnailUrl(resource?.title_image_urls?.slice(-1)?.[0])
                                      : ThumbnailPlaceholderImage
                                  }
                                  onError={(event: SyntheticEvent<HTMLImageElement, Event>) => {
                                    event.currentTarget.src = ThumbnailPlaceholderImage;
                                  }}
                                  loading="lazy"
                                  alt="Thumbnail"
                                />
                              </div>

                              <div
                                className={clsx(classes.column, classes.name)}
                                onClick={() => {
                                  setIsShowAnimation(false); // solve asynchronous issue when changing status immediately during the animation
                                  dispatch(
                                    push({
                                      pathname,
                                      search: `&resourceId=${resource?._id ?? NULL_VALUE}&workspaceId=${workspace?._id ?? NULL_VALUE}`,
                                    })
                                  );
                                }}
                              >
                                <EditIcon />
                                <p>{resource?.name ?? 'N/A'}</p>
                              </div>

                              <div
                                className={clsx(classes.column, classes.priority)}
                                onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                                  updateResourcePayload.current = {
                                    id: resource?._id,
                                    name: null,
                                    task: null,
                                  };
                                  onClickPriorityPopover(event);
                                }}
                              >
                                <Priority level={resource?.priority} />
                              </div>

                              <div
                                className={clsx(classes.column, classes.complexity)}
                                onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                                  updateResourcePayload.current = {
                                    id: resource?._id,
                                    name: null,
                                    task: null,
                                  };
                                  onClickComplexityPopover(event);
                                }}
                              >
                                <Complexity complexityLevel={resource?.complexity} />
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <p>No resources found.</p>
                      )}
                    </div>
                  </div>
                ) : (
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={
                      isShowFilters
                        ? 'calc(100vh - 24px - 40px - 24px - 38px - 12px - 38px - 12px - 32px - 56px - 40px - 10px)'
                        : 'calc(100vh - 24px - 40px - 24px - 38px - 12px - 32px - 56px - 40px - 10px)'
                    }
                    sx={{ minHeight: 'calc(100% - 32px)', borderRadius: '8px 8px 0 0', background: 'var(--backgroundLight)' }}
                  />
                )}
              </div>

              {renderTableFooter()}
            </div>

            <div className={classes.right}>
              {!resourceSummary?.isLoading &&
              pipelineList?.data &&
              resourceList?.data &&
              (resourceSummary?.pipelineId === pipelineId || !resourceList?.data?.length) ? (
                resourceList?.data?.length ? (
                  <div className={classes.rightHeader}>
                    <div className={classes.rightHeaderLeft}>
                      {resourceSummary?.data?.title_image_urls?.length ? (
                        <div className={classes.assetImage}>
                          <img
                            src={resourceSummary?.data?.title_image_urls?.slice(-1)?.[0]}
                            alt="Asset"
                            onClick={() =>
                              setLightBox({
                                isOpen: true,
                                activeIndex: resourceSummary?.data?.title_image_urls?.length - 1,
                                data: resourceSummary?.data?.title_image_urls?.map((url: string) => {
                                  return { src: url };
                                }),
                              })
                            }
                          />
                          {resourceSummary?.data?.title_image_urls?.length > 1 ? (
                            <div>
                              <p>+{resourceSummary?.data?.title_image_urls?.length - 1}</p>
                              <ImageIcon />
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <img
                          className={classes.uploadImagesPlaceholder}
                          src={UploadImagesPlaceholderImage}
                          alt="Upload Images"
                          onClick={() => imageInputRef?.current?.click()}
                        />
                      )}

                      <div className={classes.resourceInfo}>
                        <div>
                          <p className={classes.resourceTitle}>Name of asset</p>
                          <p className={classes.resourceDescription}>{resourceSummary?.data?.name ?? 'N/A'}</p>
                        </div>

                        <div>
                          <p className={classes.resourceTitle}>Progress</p>
                          <div className={classes.resourceProgress}>
                            {resourceSummary?.data?.progress_items?.length ? (
                              resourceSummary?.data?.progress_items?.map((item: IProgressItem, index: number) => {
                                const countCompleted: number = resourceSummary?.data?.progress_items?.reduce((total: number, item: IProgressItem) => {
                                  if (item?.is_complete) {
                                    return ++total;
                                  }
                                  return total;
                                }, 0);

                                return (
                                  <Fragment key={`progress-${item?.sub_resource_type}`}>
                                    <CircleProgressIcon className={clsx({ [classes.active]: index < countCompleted })} />
                                    {index !== resourceSummary?.data?.progress_items?.length - 1 ? (
                                      <div className={clsx({ [classes.active]: index < countCompleted - 1 })} />
                                    ) : null}
                                  </Fragment>
                                );
                              })
                            ) : (
                              <p className={classes.resourceDescription}>N/A</p>
                            )}
                          </div>
                        </div>

                        <div>
                          <p className={classes.resourceTitle}>Supervisors</p>
                          <div className={classes.resourceSupervisors}>
                            {resourceSummary?.data?.supervisors?.length ? (
                              resourceSummary?.data?.supervisors?.map((supervisor: ISupervisor) => {
                                return (
                                  <div key={`supervisor-${supervisor?._id}`}>
                                    <img src={supervisor?.profile_image_url ?? UserPlaceholderImage} alt="Avatar" />
                                    <p className={classes.resourceDescription}>{CommonService.getFullName(supervisor)}</p>
                                  </div>
                                );
                              })
                            ) : (
                              <p className={classes.resourceDescription}>N/A</p>
                            )}
                          </div>
                        </div>

                        <PrimaryButton className={classes.uploadImagesButton} onClick={() => imageInputRef?.current?.click()}>
                          <input
                            className="d-none"
                            ref={imageInputRef}
                            type="file"
                            multiple={true}
                            accept="image/png, image/jpg, image/jpeg"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeImage(event)}
                            onClick={(event: React.MouseEvent<HTMLInputElement>) => {
                              (event.target as HTMLInputElement).value = null;
                            }}
                          />
                          <p>Upload Images</p>
                          <UploadIcon />
                        </PrimaryButton>
                      </div>
                    </div>

                    <div className={classes.rightHeaderRight}>
                      <div>
                        <p className={classes.resourceTitle}>Start Date</p>
                        <p className={classes.resourceDescription}>
                          {resourceSummary?.data?.start_date ? dayjs(resourceSummary?.data?.start_date)?.format(DATE_FORMAT) : 'N/A'}
                        </p>
                      </div>

                      <div>
                        <p className={classes.resourceTitle}>End Date</p>
                        {/* style={{ margin: '1px 0' }} => 20px to be equal to progress bar height */}
                        <p className={classes.resourceDescription} style={{ margin: '1px 0' }}>
                          {resourceSummary?.data?.end_date ? dayjs(resourceSummary?.data?.end_date)?.format(DATE_FORMAT) : 'N/A'}
                        </p>
                      </div>

                      <div>
                        <p className={classes.resourceTitle}>UID</p>
                        <div className={classes.uidContainer}>
                          <p className={classes.resourceDescription}>{resourceSummary?.data?._id ?? 'N/A'}</p>
                          <CopyIcon
                            onClick={() => {
                              navigator?.clipboard
                                .writeText(resourceSummary?.data?._id ?? 'N/A')
                                .then(() => {
                                  ToastService.success(Messages.success.copied);
                                })
                                .catch((error) => {
                                  console.log(error);
                                  ToastService.error(Messages.error.default);
                                });
                            }}
                          />
                        </div>
                      </div>

                      <DangerButton onClick={() => setModalDeleteResource({ isOpen: true, data: editResource })}>Delete resource</DangerButton>
                    </div>
                  </div>
                ) : (
                  <div className={classes.emptyRightHeader}>No resources found.</div>
                )
              ) : (
                <Skeleton variant="rectangular" width="100%" height={269} sx={{ borderRadius: '8px', background: 'var(--backgroundLight)' }} />
              )}

              <div className={clsx(classes.rightTable, { [classes.loading]: !pipelineList?.data || !resourceList?.data || !resourceList?.data?.length })}>
                <DragDropContext
                  onDragEnd={onDragEnd}
                  onBeforeDragStart={() => {
                    onCloseStatusPopover();
                    onCloseUserPopover();
                    onClosePriorityPopover();
                    onCloseComplexityPopover();
                    document.getElementById('drop-container').scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                >
                  <Droppable droppableId="droppable" direction="horizontal">
                    {(droppableProvided: DroppableProvided, droppableSnapshot: DroppableStateSnapshot) => (
                      <div
                        id="drop-container"
                        ref={droppableProvided?.innerRef}
                        className={clsx(classes.dropContainer, {
                          [classes.hideBody]: !pipelineList?.data || !resourceList?.data || !resourceList?.data?.length,
                        })}
                        {...droppableProvided?.droppableProps}
                      >
                        {taskColumns?.map((column: ETaskColumns, columnIndex: number) => (
                          <Draggable key={`column-${columnIndex}`} draggableId={`column-${columnIndex}`} index={columnIndex}>
                            {(draggableProvided: DraggableProvided, draggableSnapshot: DraggableStateSnapshot) => (
                              <div
                                ref={draggableProvided?.innerRef}
                                {...draggableProvided?.draggableProps}
                                className={clsx(classes.dragItem, {
                                  [classes.isDragging]: draggableSnapshot?.isDragging,
                                })}
                                style={{
                                  ...draggableProvided?.draggableProps?.style,
                                  top: '0 !important',
                                  transform: draggableSnapshot?.isDropAnimating
                                    ? resetTranslateY(draggableProvided?.draggableProps?.style?.transform)
                                    : draggableProvided?.draggableProps?.style?.transform,
                                }}
                              >
                                <div
                                  className={clsx(classes.headColumn, {
                                    [classes.hoverable]: column === ETaskColumns.Stage, //|| column === ETaskColumns.Complexity
                                  })}
                                  {...draggableProvided?.dragHandleProps}
                                >
                                  {renderHeadColumn(column)}
                                </div>
                                <div className={classes.bodyColumn}>
                                  {resourceList?.resourceConfigurations
                                    // prepare for sort complexity locally
                                    ?.map((resourceConfiguration: IResourceConfiguration) => {
                                      // const subResourceTask: ISubResourceTask = editResource?.sub_resource_tasks?.find(
                                      //   (subResourceTask: ISubResourceTask) => subResourceTask?.sub_resource_type === resourceConfiguration?.name
                                      // );
                                      return resourceConfiguration;
                                      // if (sortTaskTable?.column === ETaskColumns.Complexity) {
                                      //   return {
                                      //     ...resourceConfiguration,
                                      //     complexity: subResourceTask?.complexity,
                                      //   };
                                      // } else {
                                      //   return resourceConfiguration;
                                      // }
                                    })
                                    // sort locally
                                    ?.sort((a: IResourceConfiguration, b: IResourceConfiguration) => {
                                      const comparison = a?.name?.localeCompare(b?.name);
                                      return sortTaskTable?.column === ETaskColumns.Stage
                                        ? sortTaskTable?.direction
                                          ? sortTaskTable?.direction === ESortDirection.Ascending
                                            ? comparison
                                            : -comparison
                                          : 0
                                        : 0;
                                      // : sortTaskTable?.column === ETaskColumns.Complexity
                                      // ? sortTaskTable?.direction === ESortDirection.Ascending
                                      //   ? a?.complexity > b?.complexity
                                      //     ? 1
                                      //     : -1
                                      //   : a?.complexity > b?.complexity
                                      //   ? -1
                                      //   : 1
                                      // : 0;
                                    })
                                    // render data
                                    ?.map((resourceConfiguration: IResourceConfiguration, resourceConfigurationIndex: number) => {
                                      return (
                                        <div
                                          className={clsx({
                                            [classes.hovering]: !droppableSnapshot?.isUsingPlaceholder && resourceConfigurationIndex === hoverRow,
                                          })}
                                          key={`${column}-${resourceConfigurationIndex}`}
                                          onMouseEnter={() => setHoverRow(resourceConfigurationIndex)}
                                          onMouseLeave={() => setHoverRow(null)}
                                        >
                                          {renderColumn(
                                            column,
                                            resourceConfiguration,
                                            editResource,
                                            editResource?.sub_resource_tasks?.find(
                                              (subResourceTask: ISubResourceTask) => subResourceTask?.sub_resource_type === resourceConfiguration?.name
                                            )
                                          )}
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {droppableProvided?.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>

                {!pipelineList?.data || !resourceList?.data ? (
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height="calc(100vh - 24px - 40px - 24px - 269px - 32px - 8px - 40px - 10px)"
                    sx={{
                      position: 'absolute',
                      left: '0px',
                      top: '32px',
                      minHeight: 'calc(400px - 32px)',
                      borderRadius: '8px',
                      background: 'var(--backgroundLight)',
                    }}
                  />
                ) : !resourceList?.data?.length ? (
                  <div className={classes.emptyRightTable}>No resources found.</div>
                ) : null}

                {isShowAnimation ? (
                  <ConfettiExplosion force={1} style={{ float: 'right' }} zIndex={99999} onComplete={() => setIsShowAnimation(false)} />
                ) : null}
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.noPublishedPipeline}>
            <p>Unpublished pipeline</p>
            <span>The pipeline must first be published before creating resources.</span>
            <PrimaryButton
              onClick={() =>
                dispatch(
                  push({
                    pathname: routes.private.project.edit.replace(':workspaceId', workspace?._id).replace(':projectId', project?._id),
                    search: `&name=${encodeURIComponent(project?.name)}&type=${EProjectSettings.Pipeline}&activePipelineId=${
                      pipeline?._id ?? NULL_VALUE
                    }&workspaceId=${workspace?._id ?? NULL_VALUE}`,
                  })
                )
              }
            >
              Next
            </PrimaryButton>
          </div>
        )}
      </div>

      <ReactProSidebar className={classes.resourceSidebarContainer} collapsed={!resourceSidebar?.isOpen} rtl={true} width="360px">
        <div className={classes.resourceSidebar}>
          <div className={classes.backContainer}>
            <BackIcon onClick={() => setResourceSidebar({ isOpen: false, data: null })} />
          </div>

          <p className={classes.title}>Create new resource</p>

          <Input
            className={nameList?.length ? 'mb-0' : null}
            inputRef={register('resourceName', {
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                if (event?.target?.value?.endsWith(',') && !errors?.resourceName?.message) {
                  onAddName(event?.target?.value?.slice(0, -1));
                }
              },
            })}
            label="Name"
            subLabel="Comma separated for multiple"
            placeholder="Resource name"
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event?.key === 'Enter' && !errors?.resourceName?.message) {
                onAddName(getValues('resourceName'));
              }
            }}
            errorMessage={errors?.resourceName?.message}
          />
          {nameList?.length ? (
            <div className={classes.nameListContainer}>
              {nameList?.map((name, index) => {
                return (
                  <div key={`name-${index}`}>
                    <p>{name}</p>
                    <CloseIcon onClick={() => setNameList(nameList?.filter((item) => item !== name))} />
                  </div>
                );
              })}
            </div>
          ) : null}

          <Select
            customTheme={ESelectTheme.Form}
            label="Pipeline"
            options={pipelineOptions}
            value={selectedPipeline}
            onChange={(option: any) => {
              if (option) {
                dispatch(setIsLoadingReducer(true));
                ApiService.GET(ApiRoutes.publishedPipeline.latest.replace(':pipelineId', option?.value))
                  .then((response) => {
                    if (response?.entities?.[0]?.resource_configurations?.length) {
                      setValue('selectedPipeline', option);
                    } else {
                      ToastService.error(Messages.error.noPublishedPipeline);
                    }
                  })
                  .catch((error) => {
                    if (error?.response?.status === EStatusCode.NotFound) {
                      ToastService.error(Messages.error.noPublishedPipeline);
                    } else {
                      if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
                        ToastService.error(Messages.error.accessDenied);
                        return;
                      }
                      console.log(error);
                      ToastService.error(Messages.error.default);
                    }
                  })
                  .finally(() => dispatch(setIsLoadingReducer(false)));
              }
            }}
            errorMessage={errors?.selectedPipeline?.message}
            menuPortalTarget={undefined}
          />

          <PrimaryButton
            className="mt-4"
            fullWidth
            onClick={() => {
              if (!resourceList?.resourceConfigurations?.length) {
                ToastService.error(Messages.error.noPublishedPipeline);
              } else {
                const resourceName = getValues('resourceName');
                if ((!resourceName && !nameList?.length) || !selectedPipeline) {
                  if (!resourceName && !nameList?.length) {
                    setError('resourceName', { message: 'This field is required.' });
                  }
                  if (!selectedPipeline) {
                    trigger('selectedPipeline');
                  }
                } else {
                  !errors?.resourceName?.message && !errors?.selectedPipeline?.message && onCreateResource();
                }
              }
            }}
          >
            Create {nameList?.length >= 2 ? `(${nameList?.length} resources)` : ''}
          </PrimaryButton>
        </div>
      </ReactProSidebar>

      <Lightbox
        open={lightBox?.isOpen}
        close={() => setLightBox({ isOpen: false, activeIndex: 0, data: null })}
        index={lightBox?.activeIndex}
        slides={lightBox?.data}
        controller={{ ref: lightboxRef }}
        toolbar={{
          buttons: [
            <DeleteIcon
              className={classes.deleteImageIcon}
              onClick={() => onDeleteResourceImage(lightboxRef?.current?.getLightboxState()?.currentSlide?.src)}
            />,
            'download',
            'fullscreen',
            'zoom',
            'close',
          ],
        }}
        animation={{ swipe: 0 }}
        plugins={[Counter, Thumbnails, Download, Zoom, Fullscreen]}
        counter={{ container: { style: { top: 'unset', bottom: 0 } } }}
        download={{
          download: ({ saveAs }) => {
            saveAs('resource-image');
          },
        }}
        zoom={{ maxZoomPixelRatio: 5 }}
      />

      <StatusPopover
        anchorElement={statusPopoverAnchorElement}
        onClose={onCloseStatusPopover}
        onSelect={(status: string) => {
          setIsShowAnimation(null); // solve asynchronous issue when changing status immediately during the animation
          dispatch(setIsLoadingReducer(true));
          ApiService.PATCH(
            ApiRoutes.resource.taskInfo.replace(':resourceTaskId', updateResourcePayload?.current?.id),
            {
              status,
              complexity: updateResourcePayload?.current?.task?.complexity ?? undefined,
              assigned_user_id: updateResourcePayload?.current?.task?.assigned_user_id ?? undefined,
              start_date_time: updateResourcePayload?.current?.task?.start_date_time ?? undefined,
              end_date_time: updateResourcePayload?.current?.task?.end_date_time ?? undefined,
            },
            {
              filter_task_index: 0,
              filter_sub_resource_type: updateResourcePayload?.current?.name,
            }
          )
            .then((response) => {
              const updatedResource: IResource = response?.entities?.[0];
              dispatch(
                setResourceListReducer({
                  ...resourceList,
                  data: resourceList?.data?.map((resource: IResource) => {
                    if (resource?._id === updatedResource?._id) {
                      return updatedResource;
                    }
                    return resource;
                  }),
                })
              );
              // no need to set loading reducer to false here as the dispatch below already handled it
              dispatch(getResourceSummaryRequest(updatedResource, { noSkeleton: true, loading: true }));
            })
            .catch((error) => {
              dispatch(setIsLoadingReducer(false));
              if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
                ToastService.error(Messages.error.accessDenied);
                return;
              }
              console.log(error);
              ToastService.error(Messages.error.default);
            });
        }}
      />

      <UserPopover
        anchorElement={userPopoverAnchorElement}
        onClose={onCloseUserPopover}
        onSelect={(user: ICollabUser) => {
          dispatch(setIsLoadingReducer(true));
          ApiService.PATCH(
            ApiRoutes.resource.taskInfo.replace(':resourceTaskId', updateResourcePayload?.current?.id),
            {
              assigned_user_id: user?._id,
              status: updateResourcePayload?.current?.task?.status ?? undefined,
              complexity: updateResourcePayload?.current?.task?.complexity ?? undefined,
              start_date_time: updateResourcePayload?.current?.task?.start_date_time ?? undefined,
              end_date_time: updateResourcePayload?.current?.task?.end_date_time ?? undefined,
            },
            {
              filter_task_index: 0,
              filter_sub_resource_type: updateResourcePayload?.current?.name,
            }
          )
            .then((response) => {
              const updatedResource: IResource = response?.entities?.[0];
              dispatch(
                setResourceListReducer({
                  ...resourceList,
                  data: resourceList?.data?.map((resource: IResource) => {
                    if (resource?._id === updatedResource?._id) {
                      return updatedResource;
                    }
                    return resource;
                  }),
                })
              );
            })
            .catch((error) => {
              if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
                ToastService.error(Messages.error.accessDenied);
                return;
              }
              console.log(error);
              ToastService.error(Messages.error.default);
            })
            .finally(() => dispatch(setIsLoadingReducer(false)));
        }}
      />

      <PriorityPopover
        anchorElement={priorityPopoverAnchorElement}
        onClose={onClosePriorityPopover}
        onSelect={(priority: number) => {
          dispatch(setIsLoadingReducer(true));
          if (!updateResourcePayload?.current?.name && !updateResourcePayload?.current?.task) {
            ApiService.PATCH(ApiRoutes.resource.info.replace(':resourceId', updateResourcePayload?.current?.id), {
              priority,
            })
              .then((response) => {
                const updatedResource: IResource = response?.entities?.[0];
                dispatch(
                  setResourceListReducer({
                    ...resourceList,
                    data: resourceList?.data?.map((resource: IResource) => {
                      if (resource?._id === updatedResource?._id) {
                        return updatedResource;
                      }
                      return resource;
                    }),
                  })
                );
              })
              .catch((error) => {
                if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
                  ToastService.error(Messages.error.accessDenied);
                  return;
                }
                console.log(error);
                ToastService.error(Messages.error.default);
              })
              .finally(() => dispatch(setIsLoadingReducer(false)));
          }
        }}
      />

      <ComplexityPopover
        anchorElement={complexityPopoverAnchorElement}
        onClose={onCloseComplexityPopover}
        onSelect={(complexity: number) => {
          dispatch(setIsLoadingReducer(true));
          if (!updateResourcePayload?.current?.name && !updateResourcePayload?.current?.task) {
            ApiService.PATCH(ApiRoutes.resource.info.replace(':resourceId', updateResourcePayload?.current?.id), {
              complexity,
            })
              .then((response) => {
                const updatedResource: IResource = response?.entities?.[0];
                dispatch(
                  setResourceListReducer({
                    ...resourceList,
                    data: resourceList?.data?.map((resource: IResource) => {
                      if (resource?._id === updatedResource?._id) {
                        return updatedResource;
                      }
                      return resource;
                    }),
                  })
                );
              })
              .catch((error) => {
                if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
                  ToastService.error(Messages.error.accessDenied);
                  return;
                }
                console.log(error);
                ToastService.error(Messages.error.default);
              })
              .finally(() => dispatch(setIsLoadingReducer(false)));
          } else {
            ApiService.PATCH(
              ApiRoutes.resource.taskInfo.replace(':resourceTaskId', updateResourcePayload?.current?.id),
              {
                complexity,
                assigned_user_id: updateResourcePayload?.current?.task?.assigned_user_id ?? undefined,
                status: updateResourcePayload?.current?.task?.status ?? undefined,
                start_date_time: updateResourcePayload?.current?.task?.start_date_time ?? undefined,
                end_date_time: updateResourcePayload?.current?.task?.end_date_time ?? undefined,
              },
              {
                filter_task_index: 0,
                filter_sub_resource_type: updateResourcePayload?.current?.name,
              }
            )
              .then((response) => {
                const updatedResource: IResource = response?.entities?.[0];
                dispatch(
                  setResourceListReducer({
                    ...resourceList,
                    data: resourceList?.data?.map((resource: IResource) => {
                      if (resource?._id === updatedResource?._id) {
                        return updatedResource;
                      }
                      return resource;
                    }),
                  })
                );
              })
              .catch((error) => {
                if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
                  ToastService.error(Messages.error.accessDenied);
                  return;
                }
                console.log(error);
                ToastService.error(Messages.error.default);
              })
              .finally(() => dispatch(setIsLoadingReducer(false)));
          }
        }}
      />

      <ModalDeleteResource isOpen={modalDeleteResource?.isOpen} onClose={onCloseModals} onSubmit={onDeleteResource} />
    </Container>
  );
});

export default ProjectPage;
